import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { login } from '../../../configs/textos';

function RequisitesPassword({
	checkMinLength,
	checkLetters,
	checkSpecialChars,
	password,
}) {
	const { t } = useTranslation();

	function CheckIcon({ checked }) {
		return checked ? (
			<i className='pi pi-check-circle' />
		) : (
			<i className='pi pi-times-circle' />
		);
	}

	return (
		<div>
			<div className='requisites-password-title'>{t(login.requisites)}:</div>
			<ul className='requisites-password'>
				<li
					className={classNames({
						'success-requisite': checkMinLength,
						'error-requisite': !checkMinLength && password,
					})}
				>
					<CheckIcon checked={checkMinLength} />
					{t(login.minLength)}
				</li>
				<li
					className={classNames({
						'success-requisite': checkLetters,
						'error-requisite': !checkLetters && password,
					})}
				>
					<CheckIcon checked={checkLetters} />
					{t(login.letters)}
				</li>
				<li
					className={classNames({
						'success-requisite': checkSpecialChars,
						'error-requisite': !checkSpecialChars && password,
					})}
				>
					<CheckIcon checked={checkSpecialChars} />
					{t(login.specialChars)}
				</li>
			</ul>
		</div>
	);
}
export default withTranslation('translations')(RequisitesPassword);
