import React from 'react';
import { InputText } from 'primereact/inputtext';

function Searchbar({ value, onChange, placeholder }) {
	return (
		<form>
			<span className='p-input-icon-left p-mb-3'>
				<i className='pi pi-search' />
				<InputText
					className='searchBar'
					value={value}
					onChange={onChange}
					placeholder={placeholder}
				/>
			</span>
			<input hidden disabled type='submit' />
		</form>
	);
}

export default Searchbar;
