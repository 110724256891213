import React from 'react';
import classNames from 'classnames';

const LoadingSpinner = ({ custom }) => (
	<div
		className={classNames('p-d-flex p-m-auto p-text-center', {
			'custom-loading-wrapper': custom,
		})}
	>
		<i
			className={classNames('pi pi-spin pi-spinner p-mb-3 loading', {
				'custom-loading': custom,
			})}
		/>
	</div>
);
export default LoadingSpinner;
