import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';

import CalendarField from '../../../../components/CalendarField';
import SingleData from '../../DatosClienteTable/SingleData';
import {
	dateFormatExpirationCalendar,
	endOfDayUTC,
} from '../../../../helpers/DateFormat';
import * as projectAction from '../../../../store/actions/projectAction';
import DialogFooter from '../../../../components/DialogFooter';
import { addClass, removeClass } from '../../helpers/Dialog';
import { dialogEditWallet } from '../../../../configs/textos';
import translate from '../../../../helpers/Translate';

const DialogEditWallet = ({
	open,
	setOpenDialog,
	wallet,
	i18n,
	loading,
	onSubmitEditWallet,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	function onSubmit(data) {
		onSubmitEditWallet({
			exp_date: data.exp_date ? endOfDayUTC(data.exp_date) : null,
			init_date: data.init_date ? new Date(data.init_date).toISOString() : null,
			wallet_id: wallet.wallet_id,
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) =>
		(data.init_date && !data.exp_date) || (data.exp_date && !data.init_date)
			? 'error'
			: null;

	return (
		<>
			<Form
				onSubmit={onSubmit}
				keepDirtyOnReinitialize
				initialValues={{
					init_date: wallet?.init_date ? new Date(wallet.init_date) : null,
					exp_date: wallet?.exp_date
						? dateFormatExpirationCalendar(wallet.exp_date)
						: null,
				}}
				validate={validate}
				render={({ handleSubmit, hasValidationErrors }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={t(dialogEditWallet.title)}
							visible={displayBasic}
							style={{ width: '500px' }}
							onHide={() => restoreDialogAsDefault()}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogEditWallet.save)}
									onClickHandler={handleSubmit}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<div className='p-col-12 p-mb-4 p-py-0 p-px-2 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogEditWallet.network)}
													info={
														wallet?.pocket_name
															? JSON.parse(wallet.pocket_name)[translate(i18n)]
															: '-'
													}
												/>
											</div>
											<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogEditWallet.wallet)}
													info={
														wallet?.name
															? JSON.parse(wallet.name)[translate(i18n)]
															: '-'
													}
												/>
											</div>
											<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogEditWallet.serialNumber)}
													info={`${wallet?.wallet_serial_number}${wallet?.check_digit}`}
												/>
											</div>
											<div className='p-col-12 p-px-2 p-mb-4 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogEditWallet.balance)}
													info={`${wallet.current_units_balance} ${wallet.unit_type_short_name}`}
												/>
											</div>
											<CalendarField
												id='init_date'
												label={t(dialogEditWallet.initDate)}
											/>
											<CalendarField
												id='exp_date'
												label={t(dialogEditWallet.expDate)}
											/>
											<div className='p-col-12 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogEditWallet.state)}
													info={
														wallet?.state
															? JSON.parse(wallet.state)[translate(i18n)]
															: '-'
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DialogEditWallet));
