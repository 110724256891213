export const JPG = 1;
export const PDF = 2;
export const JPG_PDF = 3;
export const DOCUMENTS_CATEGORY = 1;

// CUSTOMER REGISTER TYPES
export const NATURAL_SIGN_UP = 1;
export const GOOGLE_SIGN_UP_SOCIAL = 2;
export const APPLEID_SIGN_UP_SOCIAL = 3;
export const FACEBOOK_SIGN_UP_SOCIAL = 4;
