import React from 'react';

export default function StateMovement({ transactionStateName }) {
	if (transactionStateName === 'Operación realizada') {
		return <i className='pi pi-check' />;
	}
	if (transactionStateName === 'Operación fallida') {
		return <i className='pi pi-times' />;
	}
	if (transactionStateName === 'Pendiente') {
		return <i className='pi pi-clock' />;
	}
	return '-';
}
