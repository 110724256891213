import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getWalletSerialNumbers } from '../../../graphql/queries';

const useGetSerialNumbers = ({ networkId }) => {
	const pagination = { limit: 10, offset: 0 };
	const inputQuery = {
		filter: {
			network_id: networkId,
		},
		pagination,
		search: {
			searchFields: 'wallet_serial_number',
			searchTerm: '',
		},
	};

	const queryParams = {
		client,
		notifyOnNetworkStatusChange: true,
		variables: {
			inputFilter: inputQuery,
		},
	};

	const { data, loading, error, refetch } = useQuery(
		getWalletSerialNumbers,
		queryParams
	);

	const customRefetch = async (searchTerm, offset) => {
		const customInputFilter = {
			filter: {
				network_id: networkId,
			},
			pagination: {
				limit: 20,
				offset,
			},
			search: {
				searchFields: 'wallet_serial_number',
				searchTerm,
			},
		};

		const customQueryParams = {
			inputFilter: customInputFilter,
		};

		refetch(customQueryParams);
	};

	return { data, loading, error, refetch: customRefetch };
};

export default useGetSerialNumbers;
