import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../../client';
import { getCustomerData } from '../../../../graphql/queries';

const useGetCustomer = ({ customerId, networkId }) => {
	const queryParams = {
		client,
		variables: {
			customerId,
			networkId,
		},
	};

	return useQuery(getCustomerData, queryParams);
};

export default useGetCustomer;
