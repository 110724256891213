import React from 'react';
import { Field } from 'react-final-form';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';

export default function TextAreaField({
	id,
	label,
	placeholder,
	disabled,
	className,
	verticalStyle,
}) {
	return (
		<Field
			name={id}
			render={({ input }) => (
				<div
					className={classNames('p-mb-3 ', {
						'p-d-flex': !verticalStyle,
					})}
				>
					<label
						htmlFor={id}
						className={classNames('p-col-fixed p-text-bold ', {
							'label-dialog': !className,
							'custom-label-dialog': className,
						})}
					>
						{label}
					</label>
					<div
						className={classNames('p-d-flex p-flex-column p-jc-center ', {
							'p-ml-2': verticalStyle,
						})}
					>
						<InputTextarea
							{...input}
							id={id}
							required
							autoComplete='off'
							className={classNames('dialog-inputtextarea ', {
								'p-ml-2 p-mt-2': !disabled && verticalStyle,
								'w-350': verticalStyle,
							})}
							autoResize
							rows={10}
							cols={300}
							placeholder={placeholder}
							disabled={disabled}
						/>
					</div>
				</div>
			)}
		/>
	);
}
