import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import AppSubmenu from './AppSubmenu';

export default function Menu({ visible, onHide, items, pages }) {
	return (
		<Sidebar visible={visible} showCloseIcon={false} onHide={onHide}>
			<div className='layout-menu-container'>
				<AppSubmenu items={items} className='layout-menu' root pages={pages} />
			</div>
		</Sidebar>
	);
}
