import React from 'react';
import { error } from '../configs/textos';

function Error() {
	return (
		<div className='p-grid'>
			<div className='p-col-12'>
				<div className='card'>
					<div className='p-text-center p-col-12 p-mt-6 p-mb-6'>
						<h1>{error.nombre}</h1>
						<h2>Acceso denegado</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Error;
