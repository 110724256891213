import { useQuery } from '@apollo/client';
import { clientAuthIAM as client } from '../../../client';
import { getClientes } from '../../../graphql/queries';

const useGetClients = ({ offset, searchInput }) => {
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				pagination: { limit: 10, offset },
				search: {
					searchFields: ['customer_id', 'smart_id'],
					searchTerm: searchInput || '',
				},
				orderBy: { field: 'customer_id', direction: 'DESC' },
			},
		},
	};

	return useQuery(getClientes, queryParams);
};

export default useGetClients;
