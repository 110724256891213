import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import SingleData from '../../../components/SingleData';
import { walletDefPage } from '../../../configs/textos';
import { dateFormatUTC } from '../../../helpers/DateFormat';

function Validity({ walletDef }) {
	const { t } = useTranslation();
	return (
		<>
			<div className='p-d-flex p-ml-2 '>
				<SingleData
					label={t(walletDefPage.init)}
					info={
						walletDef?.init_date
							? dateFormatUTC(walletDef.init_date, 'DD/MM/YYYY')
							: '-'
					}
					className='walletdef-dates-label'
				/>
			</div>
			<div className='p-d-flex p-ml-2 '>
				<SingleData
					label={t(walletDefPage.end)}
					info={
						walletDef?.end_date
							? dateFormatUTC(walletDef.end_date, 'DD/MM/YYYY')
							: '-'
					}
					className='walletdef-dates-label'
				/>
			</div>
		</>
	);
}
export default withTranslation('translations')(Validity);
