import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Toast } from 'primereact/toast';
import { useApolloClient } from '@apollo/client';
import * as projectAction from '../../../store/actions/projectAction';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import { header } from '../../../configs/textos';
import ChangePasswordDialog from './RightSide/ChangePasswordDialog';

const AppTopbar = ({ onToggleMenu, setUserInfo, setNetworkId, global }) => {
	const history = useHistory();
	const client = useApolloClient();
	const toast = useRef(null);
	const { t } = useTranslation();
	const {
		user: { email },
	} = global;
	const [openDialog, setOpenDialog] = useState(false);
	const [loadingChangePassword, setLoadingChangePassword] = useState(false);

	async function logOut() {
		Auth.signOut().then(() => {
			setUserInfo(null);
			setNetworkId(null);
			client.clearStore();
			history.push('/');
		});
	}

	async function changePasswordSubmit(formData) {
		setLoadingChangePassword(true);
		Auth.currentAuthenticatedUser()
			.then((user) =>
				Auth.changePassword(user, formData.oldPassword, formData.newPassword)
			)
			.then(() => {
				setLoadingChangePassword(false);
				setOpenDialog(false);
				showSuccessToast(t(header.passwordSuccess));
			})
			.catch((err) => {
				setLoadingChangePassword(false);
				setOpenDialog(false);
				showErrorToast(t(err.message));
			});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	return (
		<div className='layout-topbar p-flex-row'>
			<Toast ref={toast} />
			<LeftSide onToggleMenu={onToggleMenu} />
			<RightSide
				logOut={logOut}
				setOpenDialog={setOpenDialog}
				userEmail={email}
			/>
			<ChangePasswordDialog
				open={openDialog}
				setOpenDialog={setOpenDialog}
				changePasswordSubmit={changePasswordSubmit}
				loading={loadingChangePassword}
			/>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(AppTopbar));
