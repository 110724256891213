import React from 'react';

export default function Title({ label }) {
	return (
		<>
			<div className='p-col-12 p-mb-0 p-py-0'>
				<div className='p-mb-0 subtitle-dialog'>{label}</div>
			</div>
			<div className='p-col-12 p-mb-3 '>
				<hr className='p-mb-0 p-mt-0 width100 separator-line' />
			</div>
		</>
	);
}
