import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getBuses } from '../../../graphql/queries';
import { clientAuthCognito as client } from '../../../client';

export default function BusesDropdown({
	label,
	id,
	networkId,
	placeholder,
	vehicleId,
}) {
	const [value, setValue] = useState('');
	const { data, loading } = useQuery(getBuses, {
		client,
		variables: {
			input: {
				filter: { network_id: { eq: networkId } },
				orderBy: { field: 'vehicle_id', direction: 'ASC' },
			},
		},
	});

	let buses = [];

	useEffect(() => {
		if (vehicleId && buses.length > 0) {
			setValue(buses.find((bus) => bus.label === vehicleId));
		}
	}, [vehicleId, loading]);

	if (loading) return <LoadingSpinner />;

	const busesList = data?.listBuses.items;

	buses = busesList.map((bus) => ({
		id: bus.bus_id,
		label: bus.vehicle_id,
	}));
	buses.unshift({ id: 0, label: 'Sin asignar' });

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							id={id}
							value={value}
							filter
							options={buses}
							optionLabel='label'
							placeholder={placeholder}
							required
							onChange={(e) => {
								setValue(e.value);
								input.onChange(e.value.id);
							}}
						/>
					</div>
				</div>
			)}
		/>
	);
}
