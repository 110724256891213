import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import * as projectAction from '../../../../store/actions/projectAction';

function NetworksDropdown({ setNetworkId, global }) {
	const { user, networkId: defaultNetworkId } = global;
	const { networks } = user;
	const [selectedNetwork, setSelectedNetwork] = useState(defaultNetworkId);

	useEffect(() => {
		setSelectedNetwork(defaultNetworkId);
	}, [defaultNetworkId]);

	const networkOptions =
		networks?.map(({ network_id: networkId, short_name: shortName }) => ({
			value: networkId,
			label: `RED ${shortName.toUpperCase()}`,
		})) || [];

	function handleOnChangeDropdown({ value }) {
		setSelectedNetwork(value);
		setNetworkId(value);
	}

	function selectedNetworkTemplate({ label }) {
		return (
			<div className='p-dropdown-item'>
				<div className='p-dropdown-item-network-text'>{label}</div>
			</div>
		);
	}

	if (networkOptions == null || networkOptions.length === 0)
		return <i className='top-bar-user-text p-ml-2'>MADO</i>;

	return networkOptions.length === 1 ? (
		<i className='top-bar-user-text p-ml-2'>{networkOptions[0].label}</i>
	) : (
		<Dropdown
			panelClassName='network-dropdown-panel'
			className='network-dropdown'
			id='networks'
			itemTemplate={selectedNetworkTemplate}
			value={selectedNetwork}
			options={networkOptions}
			required
			onChange={handleOnChangeDropdown}
		/>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(NetworksDropdown);
