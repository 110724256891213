import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../../store/actions/projectAction';
import DatosClienteTable from '../DatosClienteTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import MovementsTable from '../MovementsTable';
import useGetCustomerMovements from './hooks/useGetCustomerMovements';
import useGetCustomer from './hooks/useGetCustomer';

const Movimientos = ({ global }) => {
	const { networkId } = global;
	const { customerId } = useParams();
	const [inputQuery, setInputQuery] = useState({});
	const [offset, setOffset] = useState(0);
	const { t } = useTranslation();
	const [totalMovements, setTotalMovements] = useState(0);
	const [movements, setMovements] = useState([]);
	const toast = useRef(null);

	const { data, loading, error } = useGetCustomerMovements({
		offset,
		inputQuery,
		customerId,
		networkId,
	});

	const {
		data: customerData,
		loading: loadingCustomer,
		error: customerError,
	} = useGetCustomer({
		customerId,
		networkId,
	});

	const cliente = customerData?.getCustomerData;
	const { items: movementsData = [], total } =
		data?.listCustomerMovements || {};

	useEffect(() => {
		if (total >= 0 && movementsData) {
			setTotalMovements(total);
			setMovements(movementsData);
		}
	}, [movementsData]);

	if (error || customerError) {
		showErrorToast(error?.message || customerError?.message);
	}

	if (loadingCustomer) {
		return <LoadingSpinner />;
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onFilter(input) {
		setInputQuery(input);
	}

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className='p-container'>
			<Toast ref={toast} />
			<DatosClienteTable cliente={cliente} noEdit />
			<div className='scrollable-movement movements-table'>
				<MovementsTable
					movements={movements}
					customerId={customerId}
					title={t('Movimientos')}
					onFilter={onFilter}
					rows={15}
					total={totalMovements}
					totalRecords={totalMovements}
					onPage={onPage}
					first={offset}
					loading={loading}
					customer={cliente}
					lazy
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Movimientos));
