import moment from 'moment';
import momentTimezone from 'moment-timezone';

export function dateFormat(data, format) {
	return moment(data, moment.ISO_8601).format(format);
}

/*
To format UTC date without apply timezone, ie, the hours are not modified

Example 
dateStringUTC = "2022-10-21T00:00:00Z"
date = 2022-10-21 02:00:00 (ES GMT+2 hora de verano de Europa central)
offset = -2 -> Al corregir offset (horas + offset): 2022-10-21 00:00:00
output = 2022-10-21 (format: YYYY-MM-DD)

Example 
dateStringUTC = "2022-10-21T00:00:00Z"
date = 2022-10-20 19:00:00 (CDMX GMT-5 hora de verano central)
offset = 5 -> Al corregir offset (horas + offset): 2022-10-21 00:00:00
output = 2022-10-21 (format: YYYY-MM-DD)
*/
export function dateFormatUTC(dateStringUTC, format) {
	const date = new Date(dateStringUTC);
	if (date.getFullYear() >= 9999) {
		return 'No caduca';
	}
	const withoutOffset = dateWithoutOffset(date);
	return moment(withoutOffset).format(format);
}

export function dateFormatExpirationCalendar(data) {
	const date = new Date(data);
	const withoutOffset = dateWithoutOffset(date);
	return new Date(withoutOffset);
}

/*
Return UTC Date object from UTC string date whitout apply timezone, ie, the hours are not modified
*/
export function dateUTC(dateStringUTC) {
	const date = new Date(dateStringUTC);
	const withoutOffset = dateWithoutOffset(date);

	return new Date(withoutOffset);
}

export function endOfDayUTC(data) {
	const endDay = moment(data).endOf('day').utcOffset(0, true);
	return new Date(endDay).toISOString();
}

export function startOfDayUTC(data) {
	const startDay = moment(data).startOf('day').utcOffset(0, true);
	return new Date(startDay).toISOString();
}

export function dateFormatToISO(data, format) {
	return moment(data, moment.ISO_8601).format(format);
}

export function compareDates(initDate, endDate) {
	return moment(endDate).isBefore(initDate);
}

function dateWithoutOffset(date) {
	const offset = date.getTimezoneOffset() / 60;
	return date.setHours(date.getHours() + offset);
}

export function formatDateInLocalTimezone(date) {
	return `${
		momentTimezone
			.utc(date)
			.tz('Europe/Madrid')
			.format('YYYY-MM-DDTHH:mm:ssZ')
			.split('+')[0]
	}Z`;
}
