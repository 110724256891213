import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import WalletDetail from './WalletDetail';
import { walletDefPage } from '../../../configs/textos';

function Category({ walletDef }) {
	const { t } = useTranslation();
	return (
		<>
			<div className='p-d-flex p-ml-2'>
				<WalletDetail
					label={t(walletDefPage.duration)}
					info={
						walletDef?.val_period_count && walletDef?.period_type
							? `${walletDef.val_period_count} ${walletDef.period_type}`
							: '-'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.initType)}
					info={walletDef?.init_method || '-'}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.walletType)}
					info={
						walletDef?.unit_type && walletDef.unit_type !== '€'
							? walletDef.unit_type
							: 'Saldo'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.maxUnit)}
					info={walletDef.max_units || '-'}
				/>
			</div>
		</>
	);
}
export default withTranslation('translations')(Category);
