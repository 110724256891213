import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';

import InputField from '../../../components/InputField';
import CalendarField from '../../../components/CalendarField';
import TelCodeDropdown from './TelCodeDropdown';

import * as projectAction from '../../../store/actions/projectAction';
import DialogFooter from '../../../components/DialogFooter';
import { addClass, removeClass } from '../helpers/Dialog';
import { dialogClient } from '../../../configs/textos';
import validateEmptyFields from '../../../helpers/ValidateFields';

const DialogCliente = ({
	open,
	setOpenDialog,
	cliente,
	i18n,
	loading,
	onSubmitCliente,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	function onSubmit(data) {
		const { birth_date: birthDate, ...rest } = data;
		onSubmitCliente({
			...rest,
			customer_id: cliente.customer_id,
			...(birthDate ? { birth_date: birthDate.toISOString() } : {}),
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) =>
		validateEmptyFields(
			['name', 'surname', 'tel_number', 'birth_date', 'tel_code_id'],
			data
		) || 'error';

	return (
		<>
			<Form
				onSubmit={onSubmit}
				keepDirtyOnReinitialize
				initialValues={{
					name: cliente?.name,
					surname: cliente?.surname,
					tel_number: cliente?.tel_number,
					tel_code_id: cliente?.tel_code_id,
					birth_date: cliente?.birth_date ? new Date(cliente?.birth_date) : '',
				}}
				validate={validate}
				render={({ handleSubmit, hasValidationErrors }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={t(dialogClient.edit)}
							visible={displayBasic}
							style={{ width: '500px' }}
							onHide={() => restoreDialogAsDefault()}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogClient.save)}
									onClickHandler={handleSubmit}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<InputField id='name' label={t(dialogClient.name)} />
											<InputField
												id='surname'
												label={t(dialogClient.surname)}
											/>
											<CalendarField
												id='birth_date'
												label={t(dialogClient.birthdate)}
											/>
											<TelCodeDropdown
												i18n={i18n}
												id='tel_code_id'
												label={t(dialogClient.prefix)}
											/>
											<InputField
												id='tel_number'
												label={t(dialogClient.telephono)}
											/>
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DialogCliente));
