import React from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpiner from '../../components/LoadingSpinner';
import {
	blackListCustomerUnblockReasons,
	blackListWalletUnblockReasons,
} from '../../graphql/queries';
import { clientAuthApiKey as client } from '../../client';
import translate from '../../helpers/Translate';

export default function BlackListUnblockReasonsDropdown({
	label,
	id,
	placeholder,
	i18n,
	customer,
}) {
	const query = customer
		? blackListCustomerUnblockReasons
		: blackListWalletUnblockReasons;
	const { data, loading } = useQuery(query, {
		client,
	});

	if (loading) return <LoadingSpiner />;

	const unblockReasonsCodeList =
		data?.listCom_customer_black_list_unblock_types?.items ||
		data?.listCom_wallet_black_list_unblock_types?.items ||
		[];
	const reasons = unblockReasonsCodeList.map((reason) => ({
		name: JSON.parse(reason.name)[translate(i18n)],
		value:
			reason.customer_black_list_unblock_type_id ||
			reason.wallet_black_list_unblock_type_id,
	}));

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							id={id}
							{...input}
							options={reasons}
							optionLabel='name'
							placeholder={placeholder}
							required
						/>
					</div>
				</div>
			)}
		/>
	);
}
