import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { usersPage } from '../../../configs/textos';

function ListNetworks({ networks, removeNetwork }) {
	const { t } = useTranslation();

	if (networks == null || networks?.length === 0) {
		return (
			<div className='p-text-center p-m-3'>{t(usersPage.notConfiguration)}</div>
		);
	}

	return networks?.map((network) => (
		<div key={network.network_id} className='p-d-flex p-ai-center p-jc-center'>
			<div className='networks-div'>{network.network_name}</div>
			<div className='networks-div'>{network.role_name}</div>
			<Button
				icon='pi pi-trash'
				className='icon-button p-ml-1'
				onClick={() => removeNetwork(network)}
			/>
		</div>
	));
}
export default withTranslation('translations')(ListNetworks);
