import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import * as projectAction from '../../store/actions/projectAction';
import Searchbar from '../../components/Searchbar';
import { clientAuthCognito as client } from '../../client';
import { validadorasPage, dialog, filterBar } from '../../configs/textos';
import useGetValidadoras from './hooks/useGetValidadoras';
import ValidadorasTable from './ValidadorasTable';
import { validadorasError, validadoraTableError } from '../../configs/mensajes';
import { getValidadoras } from '../../graphql/queries';
import ValidadoraDialog from './dialogs/ValidadoraDialog';
import { createValidadora } from '../../graphql/mutations';
import { CREATE_VALIDATOR } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';
import CSVExport from '../../components/CSVExport';
import mapInput from './hooks/mapInput';
import mapValidadoras from './hooks/mapValidadoras';

function Validadoras(props) {
	const { t } = useTranslation();
	const toast = useRef(null);
	const csvExportRef = useRef(null);

	const {
		global: {
			networkId,
			user: { operations, networks },
		},
	} = props;

	const networkName = networks.find(
		(el) => el.network_id === networkId
	)?.short_name;

	const [searchInput, setSearchInput] = useState('');
	const [offset, setOffset] = useState(0);
	const [totalValidadoras, setTotalValidadoras] = useState(0);
	const [openDialogValidadora, setOpenDialogValidadora] = useState(false);
	const [dataDownloaded, setDataDownloaded] = useState(false);
	const [exportInput, setExportInput] = useState();
	const [validadorasExported, setValidadorasExported] = useState([]);

	const { data, loading, error } = useGetValidadoras({
		offset,
		searchInput,
		networkId,
	});

	const [exportValidators, { loading: exporting }] = useLazyQuery(
		getValidadoras,
		{
			client,
			variables: {
				input: exportInput,
			},
			fetchPolicy: 'network-only',
			onCompleted(result) {
				setDataDownloaded(true);
				setValidadorasExported(mapValidadoras(result?.listRes_machines?.items));
			},
		}
	);

	const [createValidadoraMutation, { loading: loadingCreateValidadora }] =
		useMutation(createValidadora, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.createValidadoraSuccess));
				setOpenDialogValidadora(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogValidadora(false);
			},
		});

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(validadorasError);
	}

	const { items: validadoras = [], total } = data?.listRes_machines || {};

	const validadorasMap = mapValidadoras(validadoras);

	useEffect(() => {
		if (total >= 0) {
			setTotalValidadoras(total);
		}
	}, [total]);

	useEffect(() => {
		if (dataDownloaded) {
			csvExportRef?.current?.link.click();
			setDataDownloaded(false);
		}
	}, [dataDownloaded]);

	useEffect(() => {
		if (exportInput) {
			exportValidators();
		}
	}, [exportInput]);

	const onPage = ({ first }) => !loading && setOffset(first);

	function onSubmitValidadora(input) {
		createValidadoraMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getValidadoras,
					variables: {
						input: {
							pagination: { limit: 10, offset },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'serial_number', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	function onExportValidators() {
		setExportInput(mapInput({ searchInput, networkId }));
	}

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>
					{t(validadorasPage.title)}
				</div>
				{hasPermission(operations, CREATE_VALIDATOR) && (
					<div>
						<Button
							label={t(validadorasPage.create).toUpperCase()}
							className='p-button'
							icon='pi pi-plus-circle'
							onClick={() => {
								setOpenDialogValidadora(true);
							}}
						/>
						<CSVExport
							data={validadorasExported}
							label={t(filterBar.export)}
							loading={exporting}
							onClickHandler={() => {
								onExportValidators();
							}}
							totalRecords={total}
							innerRef={csvExportRef}
							filename={`validadoras_${networkName}`}
							validadoras
						/>
					</div>
				)}
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<Searchbar
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
							setOffset(0);
						}}
						placeholder={t(validadorasPage.searchPlaceholder)}
					/>
					<ValidadorasTable
						value={validadorasMap}
						loading={data?.listRes_machines == null && loading}
						emptyMessage={t(validadoraTableError)}
						rows={10}
						total={totalValidadoras}
						onPage={onPage}
						first={offset}
						lazy
						networkId={networkId}
						operations={operations}
					/>
				</Card>
			</div>
			<ValidadoraDialog
				{...props}
				open={openDialogValidadora}
				setOpenDialog={setOpenDialogValidadora}
				onSubmitValidadora={onSubmitValidadora}
				loading={loadingCreateValidadora}
				networkId={networkId}
			/>
		</div>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Validadoras));
