import React, { useState } from 'react';
import classNames from 'classnames';
import RenderLink from './RenderLink';
import hasPermission from '../../../helpers/HasPermission';

function AppSubmenu({ items, className, root, pages }) {
	const [activeIndex, setActiveIndex] = useState(null);

	const UpdatedItems =
		items &&
		items.map((item, index) => {
			const active = activeIndex === index;
			const styleClass = classNames(
				{
					'active-menuitem': active,
				},
				{
					'p-d-none': hasPermission(pages, item.visible),
				},
				item.level
			);

			return (
				<li className={styleClass} key={item.label}>
					{item.items && root === true && <div className='arrow' />}
					<RenderLink
						item={item}
						index={index}
						activeIndex={activeIndex}
						setActiveIndex={setActiveIndex}
					/>
					<AppSubmenu items={item.items} pages={pages} />
				</li>
			);
		});

	return UpdatedItems ? <ul className={className}>{UpdatedItems}</ul> : <></>;
}
export default AppSubmenu;
