import React, { useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import * as projectAction from '../../store/actions/projectAction';
import useGetPockets from './hooks/useGetPockets';
import PocketsTable from './PocketsTable';
import { pocketDef } from '../../configs/textos';
import { pocketsDefError, pocketsDefTableError } from '../../configs/mensajes';

function PocketsDef(props) {
	const { t } = useTranslation();
	const toast = useRef(null);

	const {
		global: { networkId },
	} = props;

	const { data, loading, error } = useGetPockets({
		networkId,
	});

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(pocketsDefError);
	}

	const { items: pocketsDef = [] } = data?.listCom_pocket_defs || {};

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-page-title'>{t(pocketDef.title)}</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<PocketsTable
						{...props}
						value={pocketsDef}
						loading={data?.listCom_pocket_defs == null && loading}
						emptyMessage={t(pocketsDefTableError)}
						rows={10}
						total={pocketsDef.length}
						lazy
					/>
				</Card>
			</div>
		</div>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(PocketsDef));
