import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import App from './App';

import store from './store/reducers/rootReducer';

import './helpers/Translation/i18n';
import Login from './pages/Login/index';

import { clientAuthApiKey, clientAuthCognito } from './client';

ReactDOM.render(
	<ApolloProvider client={clientAuthApiKey}>
		<ApolloProvider client={clientAuthCognito}>
			<BrowserRouter>
				<Provider store={store}>
					<Login>
						<App />
					</Login>
				</Provider>
			</BrowserRouter>
		</ApolloProvider>
	</ApolloProvider>,
	document.getElementById('root')
);
