import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpiner from '../../../../components/LoadingSpinner';
import { listUpdateBalanceReasons } from '../../../../graphql/queries';
import { clientAuthApiKey as client } from '../../../../client';
import translate from '../../../../helpers/Translate';

export default function ReasonDropdown({
	label,
	id,
	placeholder,
	i18n,
	setOperation,
	setReasonName,
	reasonSelected,
}) {
	const [value, setValue] = useState('');

	useEffect(() => {
		if (reasonSelected) {
			setValue(reasons.find((reason) => reason.id === reasonSelected));
		}
	}, [reasonSelected]);

	const { data, loading } = useQuery(listUpdateBalanceReasons, {
		client,
	});

	if (loading) return <LoadingSpiner />;

	const reasonsList = data?.listCom_wallet_transaction_types.items || [];
	const reasons = reasonsList.map((reason) => {
		const operationSymbol =
			reason?.Com_wallet_transaction_operation_type
				?.wallet_transaction_operation_type_id === 1
				? '-'
				: '+';
		return {
			name: `${JSON.parse(reason?.name)[translate(i18n)]} (${operationSymbol})`,
			id: reason?.wallet_transaction_type_id,
			operation:
				reason?.Com_wallet_transaction_operation_type
					?.wallet_transaction_operation_type_id,
		};
	});

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							id={id}
							{...input}
							options={reasons}
							optionLabel='name'
							value={value}
							placeholder={placeholder}
							required
							onChange={(e) => {
								setValue(e.value);
								setOperation(e.value.operation);
								setReasonName(e.value.name);
								input.onChange(e.value.id);
							}}
						/>
					</div>
				</div>
			)}
		/>
	);
}
