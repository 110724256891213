import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { pageTittle } from '../../configs/textos';

const initState = {
	conexionError: false,
	user: null,
	networkId: null,
	breadCrumb: [],
};

const projectReducer = (state = initState, action) => {
	switch (action.type) {
		case 'initGlobal':
			return { ...state, conexionError: false };
		case 'setBreadCrumb':
			return { ...state, breadCrumb: action.dataT };
		case 'setUserInfo':
			return { ...state, user: action.dataT };
		case 'setNetworkId':
			return { ...state, networkId: action.dataT };
		default:
			return state;
	}
};

function saveToLocalStorage(store) {
	try {
		const serializedState = JSON.stringify(store);
		localStorage.setItem(pageTittle, serializedState);
	} catch (e) {
		console.log(e);
	}
}

function loadFromLocalStorage() {
	try {
		const serializedState = localStorage.getItem(pageTittle);
		const networkId = localStorage.getItem('networkId');
		if (serializedState === null) return undefined;
		return { ...JSON.parse(serializedState), networkId: Number(networkId) };
	} catch (e) {
		console.log(e);
		return undefined;
	}
}

let composeEnhancers = compose;

// This is for the redux-devtools-extension chrome extension https://github.com/zalmoxisus/redux-devtools-extension
if (
	process.env.NODE_ENV !== 'production' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const store = createStore(
	projectReducer,
	loadFromLocalStorage(),
	composeEnhancers(applyMiddleware(reduxThunk))
);

/* Actualiza el localstorage cada ves que se modifica el store */
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
