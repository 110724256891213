import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useTranslation, withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { Column } from 'primereact/column';
// import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import Filterbar from './MovimientosClienteTable/Filterbar';

import { movementTableError } from '../../configs/mensajes';
import { perfilCliente } from '../../configs/textos';
import * as projectAction from '../../store/actions/projectAction';
import { dateFormat } from '../../helpers/DateFormat';
import StateMovement from './StateMovement';
import translate from '../../helpers/Translate';

const MovementsTable = (props) => {
	const {
		movements,
		i18n,
		customerId,
		title,
		lastMovements,
		paginator,
		onFilter,
		global,
		customer,
		totalRecords,
		...restProps
	} = props;
	const {
		user: { network_id: networkId },
	} = global;
	const { t } = useTranslation();
	const toast = useRef(null);

	function onFilterHandler(data) {
		onFilter(data);
	}

	return (
		<div className='last-table p-mb-6'>
			<div className='p-d-flex p-jc-between p-ai-center'>
				<div className='p-page-title p-mt-6'>{title}</div>
				<Filterbar
					customerId={customerId}
					customer={customer}
					i18n={i18n}
					networkId={networkId}
					totalRecords={totalRecords}
					onFilter={onFilterHandler}
					movements={movements}
				/>
				{/* {lastMovements && (
					<Link to={`${movimiento.url}/${customerId}`}>
						<Button
							label={t(perfilCliente.movimientos.more).toUpperCase()}
							className='p-button'
							disabled={movements?.length === 0}
						/>
					</Link>
				)} */}
			</div>
			<div>
				<Toast ref={toast} />
				<div className='card growable p-mb-2'>
					<div className='p-grid p-ml-0 growable'>
						<div className='datatable-responsive-demo growable customize'>
							<Card
								className={classNames('growable', {
									movementsCard: !lastMovements,
								})}
							>
								<ResponsiveDataTable
									{...restProps}
									value={movements}
									emptyMessage={t(movementTableError)}
									paginator={paginator}
									className={classNames({
										movementsTable: !lastMovements,
									})}
								>
									<Column
										header={t(perfilCliente.movimientos.movement)}
										body={(row) =>
											row?.wallet_transaction_id
												? `${row.wallet_transaction_id}`
												: '-'
										}
									/>
									<Column
										header={t(perfilCliente.movimientos.serialNumber)}
										body={(row) =>
											row?.transaction_id && row?.transaction_id !== 'undefined'
												? `${row.transaction_id}`
												: '-'
										}
									/>
									<Column
										header={t(perfilCliente.movimientos.type)}
										body={(row) =>
											row?.Com_wallet_transaction_type
												? JSON.parse(row.Com_wallet_transaction_type.name)[
														translate(i18n)
												  ]
												: '-'
										}
									/>
									<Column
										header={t(perfilCliente.movimientos.amount)}
										body={(row) => (row?.amount ? `$ ${row.amount}` : '-')}
										className='width120'
									/>
									<Column
										header={t(perfilCliente.movimientos.concept)}
										body={(row) => {
											if (row?.description) {
												return row.description;
											}
											return '-';
										}}
									/>
									<Column
										header={t(perfilCliente.movimientos.date)}
										body={(row) =>
											dateFormat(row.date_time, 'DD/MM/YYYY HH:mm:ss')
										}
									/>

									<Column
										header={t(perfilCliente.movimientos.balance)}
										body={(row) =>
											row?.available_balance
												? `$ ${row.available_balance}`
												: '-'
										}
										className='width120'
									/>
									<Column
										header={t(perfilCliente.movimientos.state)}
										body={(row) =>
											row?.Com_wallet_transaction_state ? (
												<StateMovement
													transactionStateName={
														JSON.parse(row.Com_wallet_transaction_state.name)[
															translate(i18n)
														]
													}
												/>
											) : (
												'-'
											)
										}
									/>
								</ResponsiveDataTable>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(MovementsTable));
