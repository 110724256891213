import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Form } from 'react-final-form';
import InputField from '../../../components/InputField';
import DialogFooter from '../../../components/DialogFooter';
import { addClass, removeClass } from '../../DatosCliente/helpers/Dialog';
import { dialogClient, usersPage } from '../../../configs/textos';
import validateEmptyFields from '../../../helpers/ValidateFields';
import NetworksDropdown from './NetworksDropdown';
import RolesDropdown from './RolesDropdown';
import ListNetworks from './ListNetworks';

const UserDialog = (props) => {
	const { t } = useTranslation();
	const { open, setOpenDialog, loading, onSubmitUser, user } = props;
	const [displayBasic, setDisplayBasic] = useState(open);
	const [networks, setNetworks] = useState([]);

	function onSubmit(data) {
		const { network, role, email, ...rest } = data;
		onSubmitUser({
			...rest,
			...(user ? { user_id: user.user_id } : { email }),
			...(networks
				? {
						networkRoleIds: networks.map((networkArray) => ({
							network_id: networkArray.network_id,
							role_id: networkArray.role_id,
						})),
				  }
				: {}),
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
		setNetworks([]);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
			setNetworks(user?.networks || []);
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) => {
		if (!user && networks?.length === 0) return 'error';
		if (!/\S+@\S+\.\S+/.test(data.email)) return 'error';
		return validateEmptyFields(['name', 'email'], data) || 'error';
	};

	function addNetwork(data) {
		if (data.network && data.role) {
			const network = {
				network_id: data.network.network_id,
				network_name: data.network.network_name,
				role_name: data.role.role_name,
				role_id: data.role.role_id,
			};
			if (
				!networks.find(
					(networkArray) => networkArray.network_id === network.network_id
				)
			) {
				setNetworks((prevArray) => [...prevArray, network]);
			}
		}
	}

	function removeNetwork(networkRemove) {
		const networkToRemove = networks.find(
			(network) => network.network_id === networkRemove.network_id
		);
		setNetworks((prevArray) =>
			prevArray.filter(
				(network) => network.network_id !== networkToRemove.network_id
			)
		);
	}

	return (
		<>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={{
					name: user?.name || null,
					email: user?.email || null,
				}}
				render={({ handleSubmit, hasValidationErrors, form, values }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={!user ? t(usersPage.create) : t(usersPage.edit)}
							visible={displayBasic}
							style={{ width: '500px' }}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset();
							}}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogClient.save)}
									onClickHandler={() => {
										handleSubmit();
										form.reset();
									}}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<InputField
												id='name'
												label={t(usersPage.name)}
												placeholder={t(usersPage.name)}
											/>
											<InputField
												id='email'
												label={t(usersPage.email)}
												placeholder={t(usersPage.email)}
												disabled={user}
											/>
											<div className='p-col-12 p-mb-0 p-py-0 p-mt-2'>
												<div className='p-mb-0 subtitle-dialog'>
													{t(usersPage.networks).toUpperCase()}
												</div>
											</div>
											<div className='p-col-12 p-mb-3'>
												<hr className='p-mb-0 p-mt-0 width100 separator-line' />
											</div>
											<div className='p-d-flex'>
												<NetworksDropdown
													label={t(usersPage.network)}
													id='network'
													placeholder={t(usersPage.select)}
												/>
												<RolesDropdown
													{...props}
													label={t(usersPage.role)}
													id='role'
													placeholder={t(usersPage.select)}
												/>
												<div>
													<Button
														icon='pi pi-plus-circle'
														className='icon-button'
														onClick={() => {
															addNetwork(values);
															form.change('role', undefined);
															form.change('network', undefined);
														}}
													/>
												</div>
											</div>
											<hr className='small-separator-line' />
											<ListNetworks
												networks={networks}
												removeNetwork={removeNetwork}
											/>
											<hr className='small-separator-line' />
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

export default withTranslation('translations')(UserDialog);
