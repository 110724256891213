import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useMutation } from '@apollo/client';
import { classNames } from 'primereact/utils';
import { useTranslation, withTranslation } from 'react-i18next';
import { dialog, perfilCliente, button } from '../../../configs/textos';
import * as projectAction from '../../../store/actions/projectAction';
import DialogCliente from './DialogCliente';
import DialogBlackList from '../DialogBlackList';
import SingleData from './SingleData';
import Photo from './Photo';
import {
	updateCliente,
	addCustomerToBlackList,
	removeCustomerFromBlackList,
} from '../../../graphql/mutations';
import { getCustomerData } from '../../../graphql/queries';
import { EDIT_CUSTOMER, BLOCK_CUSTOMER } from '../../../configs/operations';
import { clientAuthCognito as client } from '../../../client';
import hasPermission from '../../../helpers/HasPermission';
import {
	NATURAL_SIGN_UP,
	GOOGLE_SIGN_UP_SOCIAL,
	APPLEID_SIGN_UP_SOCIAL,
	FACEBOOK_SIGN_UP_SOCIAL,
} from '../../../configs/constantes';

const DatosClienteTable = (props) => {
	const { cliente, global, noEdit, i18n } = props;
	const {
		networkId,
		user: { operations },
	} = global;
	const { t } = useTranslation();
	const toast = useRef(null);
	const [openDialogCliente, setOpenDialogCliente] = useState(false);
	const [openDialogBlackList, setOpenDialogBlackList] = useState(false);
	const [unblock, setUnblock] = useState({});

	const [addCustomerToBlackListMutation, { loading: loadingBlackList }] =
		useMutation(addCustomerToBlackList, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.addClientToBlackListSucces));
				setOpenDialogBlackList(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogBlackList(false);
			},
		});

	const [
		removeCustomerFromBlackListMutation,
		{ loading: loadingRemoveBlackList },
	] = useMutation(removeCustomerFromBlackList, {
		client,
		onCompleted() {
			showSuccessToast(t(dialog.removeClientFromBlackListSucces));
			setOpenDialogBlackList(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
			setOpenDialogBlackList(false);
		},
	});
	const [updateClientMutation, { loading: loadingUpdateClient }] = useMutation(
		updateCliente,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.updateClientSuccess));
				setOpenDialogCliente(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogCliente(false);
			},
		}
	);

	function submitCliente(clienteDatos) {
		updateClientMutation({
			variables: { clienteDatos },
			refetchQueries: [
				{
					query: getCustomerData,
					variables: { customerId: cliente?.customer_id, networkId },
				},
			],
		});
	}

	function submitBlackList(clienteBlackList) {
		const mutation = !cliente.blackList
			? addCustomerToBlackListMutation
			: removeCustomerFromBlackListMutation;
		const variables = !cliente.blackList
			? {
					description: clienteBlackList.description,
					customer_black_list_type_id:
						clienteBlackList.customer_black_list_type_id,
					customer_id: cliente.customer_id,
					network_id: networkId,
			  }
			: {
					customer_black_list_id: cliente.blackList.customer_black_list_id,
					description_unblock: clienteBlackList.description_unblock,
					customer_black_list_unblock_type_id:
						clienteBlackList.customer_black_list_unblock_type_id,
			  };
		mutation({
			variables: {
				variables,
			},
			refetchQueries: [
				{
					query: getCustomerData,
					variables: { customerId: cliente?.customer_id, networkId },
				},
			],
		});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function getRegisterType(registerTypeId) {
		let registerType;

		switch (registerTypeId) {
			case NATURAL_SIGN_UP:
				registerType = 'Natural';
				break;
			case GOOGLE_SIGN_UP_SOCIAL:
				registerType = 'Google';
				break;
			case APPLEID_SIGN_UP_SOCIAL:
				registerType = 'Apple ID';
				break;
			case FACEBOOK_SIGN_UP_SOCIAL:
				registerType = 'Facebook';
				break;
			default:
				registerType = '-';
				break;
		}

		return registerType;
	}

	return (
		<div className='cliente-table'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<h1 className='p-page-title p-p-0 p-as-end'>{t('Datos cliente')}</h1>
				{!noEdit && (
					<div>
						{hasPermission(operations, BLOCK_CUSTOMER) && (
							<Button
								label={
									!cliente?.blackList
										? t(button.block).toUpperCase()
										: t(button.unblock).toUpperCase()
								}
								className={classNames('p-mr-3', {
									'button-unblock': cliente?.blackList,
									'button-block': !cliente?.blackList,
								})}
								icon={!cliente?.blackList ? 'pi pi-lock' : 'pi pi-unlock'}
								onClick={() => {
									setOpenDialogBlackList(true);
									setUnblock(cliente?.blackList);
								}}
							/>
						)}
						{hasPermission(operations, EDIT_CUSTOMER) && (
							<Button
								label={t(button.edit).toUpperCase()}
								className='p-button'
								icon='pi pi-pencil'
								onClick={() => {
									setOpenDialogCliente(true);
								}}
							/>
						)}
					</div>
				)}
			</div>
			<div>
				<Toast ref={toast} />
				<div
					className={classNames(
						'card p-d-flex p-flex-column p-flex-lg-row p-jc-between',
						{
							'table-block': cliente?.blackList,
							'table-not-block': !cliente?.blackList,
						}
					)}
				>
					<div
						className={classNames({
							'color-div': !cliente?.blackList,
							'color-div-blocked': cliente?.blackList,
						})}
					/>
					<div className='p-lg-9 p-p-4'>
						<div className='p-col-12 p-d-flex p-flex-column p-flex-md-row'>
							<div className='p-col-12 p-md-7 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.name)}
									info={
										cliente?.name ? `${cliente.name} ${cliente?.surname}` : '-'
									}
								/>
							</div>
							<div className='p-col-12 p-md-6 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.birthdate)}
									info={
										cliente?.birth_date
											? moment(cliente.birth_date, moment.ISO_8601).format(
													'DD/MM/YYYY'
											  )
											: '-'
									}
								/>
							</div>
						</div>
						<div className='p-col-12 p-d-flex p-flex-column p-flex-md-row'>
							<div className='p-col-12 p-md-7 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.document)}
									info={cliente?.document_id || '-'}
								/>
							</div>
							<div className='p-col-12 p-md-6 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.telephone)}
									info={
										cliente?.tel_number
											? `${cliente?.tel_code} ${cliente.tel_number}`
											: '-'
									}
								/>
							</div>
						</div>
						<div className='p-col-12 p-d-flex p-flex-column p-flex-md-row'>
							<div className='p-col-12 p-md-7 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.email)}
									info={cliente?.email || '-'}
								/>
							</div>
							<div className='p-col-12 p-md-6 p-m-0 p-p-0 p-d-flex p-flex-column p-flex-lg-row'>
								<SingleData
									label={t(perfilCliente.datos.registerType)}
									info={getRegisterType(cliente?.register_type_id)}
								/>
							</div>
						</div>
					</div>
					<div className='p-lg-2 p-text-center p-pt-3'>
						<Photo photo={cliente.photo} />
					</div>
				</div>
			</div>
			<DialogCliente
				{...props}
				open={openDialogCliente}
				setOpenDialog={setOpenDialogCliente}
				cliente={cliente}
				i18n={i18n}
				loading={loadingUpdateClient}
				onSubmitCliente={submitCliente}
			/>
			<DialogBlackList
				{...props}
				open={openDialogBlackList}
				setOpenDialog={setOpenDialogBlackList}
				title={
					!cliente?.blackList
						? t(dialog.clientBlackList)
						: t(dialog.clientBlackListRemove)
				}
				name={`${cliente?.name} ${cliente?.surname}`}
				unblock={unblock}
				loading={loadingBlackList || loadingRemoveBlackList}
				id={cliente?.customer_id}
				onSubmitBlackList={submitBlackList}
				i18n={i18n}
				customer={cliente}
			/>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DatosClienteTable));
