import React from 'react';

export default function CertificateState({ state, webColor, textColor }) {
	return (
		<div
			className='p-p-2 p-text-center certificate-state'
			style={{ backgroundColor: webColor, color: textColor }}
		>
			{state}
		</div>
	);
}
