import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation, withTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { validadorasPage, dialog, busesPage } from '../../configs/textos';
import { dateFormat, dateFormatUTC } from '../../helpers/DateFormat';
import ActionBodyTemplate from '../../components/ActionBodyTemplate';
import ValidadoraDialog from './dialogs/ValidadoraDialog';
import { clientAuthCognito as client } from '../../client';
import { updateValidadora, deleteValidadora } from '../../graphql/mutations';
import { getValidadoras } from '../../graphql/queries';
import { EDIT_VALIDATOR } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';

const ValidadorasTable = (props) => {
	const { t } = useTranslation();
	const { networkId, operations } = props;
	const [validadora, setValidadora] = useState(null);
	const [openDialogValidadora, setOpenDialogValidadora] = useState(false);
	const [openDeleteValidadora, setOpenDeleteValidadora] = useState(false);
	const toast = useRef(null);
	const { first } = props;

	const [updateValidadoraMutation, { loading: loadingUpdateValidadora }] =
		useMutation(updateValidadora, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.updateValidadoraSuccess));
				setOpenDialogValidadora(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogValidadora(false);
			},
		});

	const [deleteValidadoraMutation] = useMutation(deleteValidadora, {
		client,
		onCompleted() {
			showSuccessToast(t(dialog.deleteValidadoraSuccess));
			setOpenDeleteValidadora(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
			setOpenDeleteValidadora(false);
		},
	});

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onSubmitValidadora(input) {
		updateValidadoraMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getValidadoras,
					variables: {
						input: {
							pagination: { limit: 10, offset: first },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'serial_number', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	function onSubmitDeleteValidadora() {
		deleteValidadoraMutation({
			variables: { input: { machine_id: validadora.machine_id } },
			refetchQueries: [
				{
					query: getValidadoras,
					variables: {
						input: {
							pagination: { limit: 10, offset: first },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'serial_number', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	return (
		<>
			<Toast ref={toast} />
			<ResponsiveDataTable {...props}>
				<Column
					field='serial_number'
					header={t(validadorasPage.serialNumber)}
				/>
				<Column field='mac' header={t(validadorasPage.mac)} />
				<Column
					field='vehicle_id'
					header={t(validadorasPage.bus)}
					body={(row) =>
						row?.vehicle_id ? row.vehicle_id : t(busesPage.noMachines)
					}
				/>
				<Column
					field='init_date'
					header={t(validadorasPage.initDate)}
					body={(row) =>
						row?.init_date ? dateFormatUTC(row.init_date, 'DD/MM/YYYY') : '-'
					}
				/>
				<Column
					field='last_sync_keep_alive'
					header={t(validadorasPage.lastSync)}
					body={(row) =>
						row?.last_sync_keep_alive
							? dateFormat(row.last_sync_keep_alive, 'DD/MM/YYYY HH:mm:ss')
							: '-'
					}
				/>
				{hasPermission(operations, EDIT_VALIDATOR) && (
					<Column
						body={(row) => (
							<ActionBodyTemplate
								onDeleteClick={() => {
									setValidadora(row);
									setOpenDeleteValidadora(true);
								}}
								onEditClick={() => {
									setOpenDialogValidadora(true);
									setValidadora(row);
								}}
							/>
						)}
					/>
				)}
			</ResponsiveDataTable>
			<ValidadoraDialog
				{...props}
				open={openDialogValidadora}
				setOpenDialog={setOpenDialogValidadora}
				onSubmitValidadora={onSubmitValidadora}
				loading={loadingUpdateValidadora}
				networkId={networkId}
				validadora={validadora}
			/>
			<ConfirmDialog
				visible={openDeleteValidadora}
				onHide={() => setOpenDeleteValidadora(false)}
				message={
					<>
						¿Está seguro de que desea eliminar la validadora con número de serie{' '}
						<b>{validadora?.serial_number}</b>?
					</>
				}
				header='Eliminar validadora'
				accept={() => onSubmitDeleteValidadora(validadora)}
				reject={() => setOpenDeleteValidadora(false)}
				acceptLabel={t(dialog.delete)}
				rejectLabel={t(dialog.cancel)}
				rejectClassName='rejectButton'
				acceptClassName='acceptDelete'
				className='confirmDialog'
			/>
		</>
	);
};

export default withTranslation('translations')(ValidadorasTable);
