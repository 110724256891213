import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { clientAuthCognito as client } from '../../client';
import { createBus } from '../../graphql/mutations';
import * as projectAction from '../../store/actions/projectAction';
import Searchbar from '../../components/Searchbar';
import { busesPage, dialog } from '../../configs/textos';
import useGetBuses from './hooks/useGetBuses';
import BusesTable from './BusesTable';
import { busesError, busesTableError } from '../../configs/mensajes';
import BusDialog from './dialogs/BusDialog';
import { getBuses } from '../../graphql/queries';
import { CREATE_BUS } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';

function Buses(props) {
	const { t } = useTranslation();
	const toast = useRef(null);

	const {
		global: {
			networkId,
			user: { operations },
		},
	} = props;

	const [searchInput, setSearchInput] = useState('');
	const [offset, setOffset] = useState(0);
	const [totalBuses, setTotalBuses] = useState(0);
	const [openDialogBus, setOpenDialogBus] = useState(false);

	const { data, loading, error } = useGetBuses({
		offset,
		searchInput,
		networkId,
	});

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(busesError);
	}

	const { items: buses = [], total } = data?.listBuses || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalBuses(total);
		}
	}, [total]);

	const onPage = ({ first }) => !loading && setOffset(first);

	const [createBusMutation, { loading: loadingCreateBus }] = useMutation(
		createBus,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.createBusSuccess));
				setOpenDialogBus(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogBus(false);
			},
		}
	);

	function onSubmitBus(input) {
		createBusMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getBuses,
					variables: {
						input: {
							pagination: { limit: 10, offset },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'plate', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>{t(busesPage.title)}</div>
				{hasPermission(operations, CREATE_BUS) && (
					<div>
						<Button
							label={t(busesPage.create).toUpperCase()}
							className='p-button'
							icon='pi pi-plus-circle'
							onClick={() => {
								setOpenDialogBus(true);
							}}
						/>
					</div>
				)}
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<Searchbar
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
							setOffset(0);
						}}
						placeholder={t(busesPage.searchPlaceholder)}
					/>
					<BusesTable
						value={buses}
						loading={data?.listBuses == null && loading}
						emptyMessage={t(busesTableError)}
						rows={10}
						total={totalBuses}
						onPage={onPage}
						first={offset}
						lazy
					/>
				</Card>
			</div>
			<BusDialog
				{...props}
				open={openDialogBus}
				setOpenDialog={setOpenDialogBus}
				onSubmitBus={onSubmitBus}
				loading={loadingCreateBus}
				networkId={networkId}
			/>
		</div>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Buses));
