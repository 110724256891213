import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getValidadoras } from '../../../graphql/queries';
import mapInput from './mapInput';

const useGetValidadoras = ({ offset, searchInput, networkId }) => {
	const pagination = { limit: 15, offset };
	const input = mapInput({ networkId, searchInput, pagination });
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input,
		},
	};

	return useQuery(getValidadoras, queryParams);
};

export default useGetValidadoras;
