import React from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { listTelephonoCode } from '../../../graphql/queries';
import { clientAuthApiKey as client } from '../../../client';
import translate from '../../../helpers/Translate';

export default function TelCodeDropdown({ label, i18n, id }) {
	const { data, loading } = useQuery(listTelephonoCode, {
		client,
	});

	if (loading) return <LoadingSpinner />;

	const telephonoCodeList = data?.listCust_tel_codes.items;

	const codes = telephonoCodeList.map((code) => ({
		value: code.tel_code_id,
		label: `${JSON.parse(code.name)[translate(i18n)]} (${code.code})`,
	}));

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							id={id}
							{...input}
							options={codes}
							optionLabel='label'
							required
						/>
					</div>
				</div>
			)}
		/>
	);
}
