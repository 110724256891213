export function addClass(element, className) {
	if (element.classList) element.classList.add(className);
	else element.className += ` ${className}`;
}

export function removeClass(element, className) {
	if (element.classList) element.classList.remove(className);
	else
		element.className = element.className.replace(
			new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
			' '
		);
}

export function validateDocument(value, documentTypeName) {
	if (value) {
		if (documentTypeName === 'DNI' || documentTypeName === 'NIE') {
			const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
			const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
			const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
			const str = value.toUpperCase();

			if (documentTypeName === 'DNI' && !nifRexp.test(str)) {
				return false;
			}

			if (documentTypeName === 'NIE' && !nieRexp.test(str)) {
				return false;
			}

			const nie = str
				.replace(/^[X]/, '0')
				.replace(/^[Y]/, '1')
				.replace(/^[Z]/, '2');

			const letter = str.substr(-1);
			const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

			return validChars.charAt(charIndex) === letter;
		}
		return true;
	}
	return true;
}
