import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
// import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useLazyQuery } from '@apollo/client';
import { clientAuthSaldos as client } from '../../../../client';
import { getClienteWallet } from '../../../../graphql/queries';
// import WalletSerialNumberDropdown from './WalletSerialNumberDropdown';
// import TransactionTypeDropdown from './TransactionTypeDropdown';
// import CalendarField from '../../../../components/CalendarField';
import { compareDates } from '../../../../helpers/DateFormat';
import CSVExport from '../../../../components/CSVExport';
import { filterBar } from '../../../../configs/textos';
import mapFilter from '../hooks/mapFilter';

const totalRecordsLimit = 30000;

function Filterbar({ customerId, i18n, onFilter, networkId, totalRecords }) {
	const { t } = useTranslation();
	const [errorDates, setErrorDates] = useState('');
	const [movements, setMovements] = useState([]);
	const [dataDownloaded, setDataDownloaded] = useState(false);
	const [inputFilter, setInputFilter] = useState();
	const [filter, setFilter] = useState();
	const csvExportRef = useRef(null);
	const toast = useRef(null);

	useEffect(() => {
		if (inputFilter) {
			exportMovements();
		}
	}, [inputFilter]);

	useEffect(() => {
		if (dataDownloaded) {
			csvExportRef?.current?.link.click();
			setDataDownloaded(false);
		}
	}, [dataDownloaded]);

	const [exportMovements, { loading }] = useLazyQuery(getClienteWallet, {
		client,
		variables: {
			customerId,
			inputFilter,
		},
		fetchPolicy: 'network-only',
		onCompleted(data) {
			setDataDownloaded(true);
			setMovements(data?.listCom_wallet_transactions?.items);
		},
	});
	function onSubmit(data) {
		const { init_date: initDate, end_date: endDate, ...rest } = data;
		setFilter({
			...rest,
			...(initDate ? { init_date: initDate.toISOString() } : {}),
			...(endDate
				? {
						end_date: new Date(endDate.setHours(23, 59, 59, 999)).toISOString(),
				  }
				: {}),
		});
		onFilter({
			...rest,
			...(initDate ? { init_date: initDate.toISOString() } : {}),
			...(endDate
				? {
						end_date: new Date(endDate.setHours(23, 59, 59, 999)).toISOString(),
				  }
				: {}),
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onExportMovements() {
		if (totalRecords > totalRecordsLimit) {
			showErrorToast('Descarga no disponible debido al alto número de datos.');
		} else {
			setInputFilter(mapFilter(filter, customerId, networkId));
		}
	}

	function validate(data) {
		if (data.init_date && data.end_date) {
			if (compareDates(data.init_date, data.end_date)) {
				setErrorDates(
					'La fecha de fin debe ser posterior a la fecha de inicio'
				);
				return 'error';
			}
			setErrorDates('');
		}
		if (Object.keys(data).length === 0) {
			return 'error';
		}
	}
	return (
		<div className=''>
			<Toast ref={toast} />

			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} className='p-col-12 p-p-0'>
						<div className=''>
							<CSVExport
								i18n={i18n}
								data={movements}
								label={t(filterBar.export)}
								loading={loading}
								onClickHandler={() => {
									onExportMovements();
								}}
								innerRef={csvExportRef}
								filename={`movimientos_${customerId}`}
							/>
							<div className='p-error p-ml-3'>{errorDates}</div>
						</div>
					</form>
				)}
			/>
		</div>
	);
}
export default withTranslation('translations')(Filterbar);
