import React from 'react';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { clientAuthApiKey as client } from '../../../client';
import { listTransactionTypes } from '../../../graphql/queries';
import LoadingSpinner from '../../../components/LoadingSpinner';
import translate from '../../../helpers/Translate';

export default function TransactionTypeDropdown({ i18n }) {
	const { data, loading } = useQuery(listTransactionTypes, {
		client,
	});

	if (loading) {
		return <LoadingSpinner custom />;
	}

	const transactions = data?.listCom_wallet_transaction_types?.items || [];
	const transactionsTypes = transactions.map((reason) => {
		const operationSymbol =
			reason?.Com_wallet_transaction_operation_type
				?.wallet_transaction_operation_type_id === 1
				? '-'
				: '+';
		return {
			label: reason?.operable
				? `${JSON.parse(reason?.name)[translate(i18n)]} (${operationSymbol})`
				: JSON.parse(reason?.name)[translate(i18n)],
			value: reason?.wallet_transaction_type_id,
		};
	});

	return (
		<Field
			name='wallet_transaction_type_id'
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-2'>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							id='wallet_transaction_type_id'
							options={transactionsTypes}
							optionLabel='label'
							placeholder='Tipo'
							required
							className='movements-dropdown'
						/>
					</div>
				</div>
			)}
		/>
	);
}
