import React from 'react';

export default function UserNetworks({ networks }) {
	return networks?.map((network) => {
		if (network.network_name && network?.role_name) {
			return (
				<ul className='network-list'>
					{`${network.network_name}
			(${network?.role_name})`}
				</ul>
			);
		}
		return 'Ninguna';
	});
}
