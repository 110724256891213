import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { dateFormatUTC } from '../../helpers/DateFormat';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { monedero } from '../../configs/textos';
import AccountLink from './AccountLink';
import * as projectAction from '../../store/actions/projectAction';

const MonederosTable = (props) => {
	const { t } = useTranslation();

	function rowClass(data) {
		return {
			'row-blocked': data.in_blacklist,
		};
	}

	return (
		<ResponsiveDataTable {...props} rowClassName={rowClass}>
			<Column field='wallet_id' header={t(monedero.table.wallet_id)} />
			<Column
				field='current_units_balance'
				header={t(monedero.table.amount)}
				body={(rowData) =>
					rowData?.current_units_balance
						? `$ ${rowData.current_units_balance}`
						: '-'
				}
			/>

			<Column
				field='wallet_serial_number'
				header={t(monedero.table.nserie)}
				body={(rowData) =>
					rowData?.wallet_serial_number
						? `${rowData.wallet_serial_number}`
						: '-'
				}
			/>

			<Column
				header={t(monedero.table.inicio)}
				body={(rowData) =>
					rowData.init_date
						? dateFormatUTC(rowData.init_date, 'DD/MM/YYYY')
						: '-'
				}
				style={{ width: '125px' }}
			/>
			<Column
				header={t(monedero.table.expired)}
				body={(rowData) =>
					rowData.exp_date ? dateFormatUTC(rowData.exp_date, 'DD/MM/YYYY') : '-'
				}
				style={{ width: '125px' }}
			/>

			<Column
				body={(e) => (
					<AccountLink
						customerId={e.Cust_pocket.customer_id}
						label={t('Ver billetera')}
					/>
				)}
			/>
		</ResponsiveDataTable>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(MonederosTable));
