import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getBuses } from '../../../graphql/queries';

const useGetBuses = ({ offset, searchInput, networkId }) => {
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				pagination: { limit: 10, offset },
				search: {
					searchFields: ['vehicle_id'],
					searchTerm: searchInput || '',
				},
				filter: { network_id: { eq: networkId } },
				orderBy: { field: 'plate', direction: 'ASC' },
			},
		},
	};

	return useQuery(getBuses, queryParams);
};

export default useGetBuses;
