const mapInput = ({ networkId, pagination, searchInput }) => ({
	pagination,
	search: {
		searchFields: ['serial_number'],
		searchTerm: searchInput || '',
	},
	filter: { network_id: { eq: networkId }, active: { eq: true } },
	orderBy: { field: 'serial_number', direction: 'ASC' },
});

export default mapInput;
