import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import Title from '../../components/Title';
import { walletDefPage } from '../../configs/textos';
import Conditions from './components/Conditions';
import Category from './components/Category';
import WalletData from './components/WalletData';
import Validity from './components/Validity';

function WalletDefCard(props) {
	const { walletDef } = props;
	const { t } = useTranslation();
	return (
		<>
			<div className='p-page-title'>{t(walletDefPage.title)}</div>
			<Card>
				<Title label={t(walletDefPage.data.toUpperCase())} />
				<WalletData walletDef={walletDef} {...props} />
				<div className='p-d-flex p-mt-5'>
					<div className='third'>
						<Title label={t(walletDefPage.validity.toUpperCase())} />
						<Validity walletDef={walletDef} />
					</div>
					<div className='third'>
						<Title label={t(walletDefPage.category.toUpperCase())} />
						<Category walletDef={walletDef} />
					</div>
					<div className='third'>
						<Title label={t(walletDefPage.conditions.toUpperCase())} />
						<Conditions walletDef={walletDef} />
					</div>
				</div>
			</Card>
		</>
	);
}

export default withTranslation('translations')(WalletDefCard);
