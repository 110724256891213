/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const createCertificate = gql`
	mutation MyMutation(
		$certificado: CreateCust_named_customer_certificateInput!
	) {
		createCust_named_customer_certificate(input: $certificado) {
			named_customer_certificate_id
			init_date
			exp_date
			active
		}
	}
`;

export const updateCertificate = gql`
	mutation MyMutation(
		$certificado: UpdateCust_named_customer_certificateInput!
	) {
		updateCust_named_customer_certificate(input: $certificado) {
			named_customer_certificate_id
			init_date
			exp_date
			active
		}
	}
`;

export const updateCliente = gql`
	mutation MyMutation($clienteDatos: UpdateCust_named_customer_CCWInput!) {
		updateCust_named_customer_CCW(input: $clienteDatos) {
			customer_id
			name
			surname
			tel_number
			birth_date
		}
	}
`;

export const addCustomerToBlackList = gql`
	mutation MyMutation($variables: CreateCom_customer_black_listInput!) {
		addCustomerToBlackList(input: $variables) {
			customer_black_list_id
			customer_id
		}
	}
`;

export const removeCustomerFromBlackList = gql`
	mutation MyMutation($variables: RemoveCom_customer_black_listInput!) {
		removeCustomerFromBlackList(input: $variables) {
			customer_black_list_id
			description_unblock
		}
	}
`;

export const updateWallet = gql`
	mutation MyMutation($updatedWallet: UpdateCust_wallet_CCWInput!) {
		updateCust_wallet_CCW(input: $updatedWallet) {
			wallet_id
		}
	}
`;

export const updateWalletBalance = gql`
	mutation MyMutation($updatedWalletBalance: UpdateCustWalletBalanceInput!) {
		updateCustWalletBalance(input: $updatedWalletBalance) {
			successful
		}
	}
`;

export const addWalletToBlackList = gql`
	mutation MyMutation($variables: CreateCom_wallet_black_listInput!) {
		addWalletToBlackList(input: $variables) {
			wallet_black_list
			wallet_id
		}
	}
`;

export const removeWalletFromBlackList = gql`
	mutation MyMutation($variables: RemoveCom_wallet_black_listInput!) {
		removeWalletFromBlackList(input: $variables) {
			wallet_black_list
			wallet_id
		}
	}
`;

export const createBus = gql`
	mutation MyMutation($input: CreateRes_busInput!) {
		createRes_bus(input: $input) {
			bus_id
		}
	}
`;

export const editBus = gql`
	mutation MyMutation($input: UpdateRes_busInput!) {
		updateRes_bus(input: $input) {
			bus_id
			plate
		}
	}
`;

export const deleteBus = gql`
	mutation MyMutation($input: RemoveRes_busInput!) {
		removeResBus(input: $input) {
			successful
		}
	}
`;

export const createValidadora = gql`
	mutation MyMutation($input: CreateRes_machineInput!) {
		createRes_machine(input: $input) {
			successful
		}
	}
`;

export const updateValidadora = gql`
	mutation MyMutation($input: UpdateRes_machineInput!) {
		updateRes_machine(input: $input) {
			successful
		}
	}
`;

export const deleteValidadora = gql`
	mutation MyMutation($input: RemoveRes_machineInput!) {
		removeRes_machine(input: $input) {
			successful
		}
	}
`;

export const createUser = gql`
	mutation MyMutation($input: CreateSysUserInput!) {
		createSysUser(input: $input) {
			email
		}
	}
`;

export const editUser = gql`
	mutation MyMutation($input: UpdateSysUser!) {
		updateSysUser(input: $input) {
			successful
		}
	}
`;

export const deleteUser = gql`
	mutation MyMutation($input: DeleteSysUser!) {
		deleteSysUser(input: $input) {
			successful
		}
	}
`;

export const validateCertificate = gql`
	mutation MyMutation($input: NamedCustomerCertificateIdInput!) {
		validateCertificate(input: $input) {
			successful
		}
	}
`;

export const denyCertificate = gql`
	mutation MyMutation($input: DenyCertificate_Input!) {
		denyCustomerCertificate(input: $input) {
			successful
		}
	}
`;

export const createCertificateMutation = gql`
	mutation MyMutation($input: CreateCustomerCertificateInput!) {
		createCustomerCertificate(input: $input) {
			named_customer_certificate_id
		}
	}
`;

export const updateCustomerCertificate = gql`
	mutation MyMutation($input: UpdateCustomerCertificateInput!) {
		updateCustomerCertificate(input: $input) {
			successful
		}
	}
`;

export const removeCustomerCertificate = gql`
	mutation MyMutation($input: NamedCustomerCertificateIdInput!) {
		removeCustomerCertificate(input: $input) {
			successful
		}
	}
`;
