import React from 'react';
import { Button } from 'primereact/button';

function SimpleDialogFooter({
	labelCancel,
	labelSubmit,
	onClickCancel,
	onClickSubmit,
	loading,
}) {
	return (
		<>
			<Button
				label={labelCancel}
				className='p-button-secondary'
				onClick={onClickCancel}
				disabled={loading}
			/>
			<Button
				label={labelSubmit}
				className='p-button-danger p-mt-5'
				onClick={onClickSubmit}
				loading={loading}
			/>
		</>
	);
}
export default SimpleDialogFooter;
