import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { listServiceClassNames } from '../../../graphql/queries';
import { clientAuthCognito as client } from '../../../client';

export default function ServiceClassDropdown({
	label,
	id,
	networkId,
	placeholder,
	serviceClassId,
}) {
	const [value, setValue] = useState('');

	const { data, loading } = useQuery(listServiceClassNames, {
		client,
		variables: { networkId },
	});

	let serviceClassNames = [];

	useEffect(() => {
		if (serviceClassId) {
			setValue(
				serviceClassNames?.find((service) => service.id === serviceClassId)
			);
		}
	}, [serviceClassId, data]);

	if (loading) return <LoadingSpinner custom />;

	const response = data?.listCom_service_classs.items || [];

	serviceClassNames = response.map((item) => ({
		id: item.service_class_id,
		label: item.long_name,
	}));

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							id={id}
							value={value}
							placeholder={placeholder}
							options={serviceClassNames}
							optionLabel='label'
							required
							onChange={(e) => {
								setValue(e.value);
								input.onChange(e.value.id);
							}}
						/>
					</div>
				</div>
			)}
		/>
	);
}
