import React from 'react';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { clientAuthCognito as client } from '../../../client';
import { listCertificateStates } from '../../../graphql/queries';
import LoadingSpinner from '../../../components/LoadingSpinner';
import translate from '../../../helpers/Translate';

export default function CertificateStateDropdown({ i18n }) {
	const { data, loading } = useQuery(listCertificateStates, {
		client,
	});

	if (loading) {
		return <LoadingSpinner custom />;
	}

	const states = data?.listCust_named_customer_certificate_states?.items || [];
	const certificateStates = states.map((state) => ({
		label: JSON.parse(state?.name)[translate(i18n)],
		value: state?.named_customer_certificate_state_id,
	}));

	return (
		<Field
			name='named_customer_certificate_state_id'
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-my-3 p-mb-2'>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							id='named_customer_certificate_state_id'
							options={certificateStates}
							optionLabel='label'
							placeholder='Estado'
							required
							className='movements-dropdown'
						/>
					</div>
				</div>
			)}
		/>
	);
}
