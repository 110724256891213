import React from 'react';

export default function SingleData({ label, info }) {
	return (
		<>
			<div className='p-col-12 p-lg-5 p-text-bold p-p-0'>{label}</div>
			<div className='p-col-12 p-lg-7 p-p-0 text-secondary'>{info || '-'}</div>
		</>
	);
}
