import React from 'react';
import { Button } from 'primereact/button';

export default function DialogFooter({
	label,
	disabled,
	loading,
	onClickHandler,
	className,
	margin,
}) {
	const addMargin = margin ? 'p-mr-5' : null;
	return (
		<Button
			label={label}
			icon={!className ? 'pi pi-check' : 'pi pi-unlock'}
			disabled={disabled}
			loading={loading}
			className={className || `botonDialog ${addMargin}`}
			onClick={onClickHandler}
		/>
	);
}
