import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../../client';
import { getCustomerMovements } from '../../../../graphql/queries';
import mapFilter from './mapFilter';

const useGetCustomerMovements = ({
	offset,
	inputQuery,
	customerId,
	networkId,
}) => {
	const pagination = { limit: 15, offset };
	const inputFilter = mapFilter(inputQuery, customerId, networkId, pagination);

	const queryParams = {
		client,
		variables: {
			customerId,
			inputFilter,
		},
	};

	return useQuery(getCustomerMovements, queryParams);
};

export default useGetCustomerMovements;
