import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useMutation } from '@apollo/client';
import { clientAuthCognito as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import Searchbar from '../../components/Searchbar';
import { usersPage, dialog } from '../../configs/textos';
import { usersError, usersTableError } from '../../configs/mensajes';
import UsersTable from './UsersTable';
import useGetUsers from './hooks/useGetUsers';
import UserDialog from './dialogs/UserDialog';
import { getUsers } from '../../graphql/queries';
import { createUser } from '../../graphql/mutations';
import { CREATE_USER } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';
import translate from '../../helpers/Translate';

function Usuarios(props) {
	const {
		i18n,
		global: {
			user: { operations },
		},
	} = props;
	const { t } = useTranslation();
	const toast = useRef(null);

	const [searchInput, setSearchInput] = useState('');
	const [offset, setOffset] = useState(0);
	const [totalUsers, setTotalUsers] = useState(0);
	const [openCreateUser, setOpenCreateUser] = useState(false);

	const { data, loading, error } = useGetUsers({
		offset,
		searchInput,
	});

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(usersError);
	}

	const { items: users = [], total } = data?.listSys_users || {};

	const usersMap =
		users?.map(({ Sys_user_network: userNetwork, ...rest }) => ({
			...rest,
			networks: userNetwork?.items?.map(
				({ Org_network: orgNetwork, Sys_role: sysRole }) => ({
					role_name: JSON.parse(sysRole?.name)[translate(i18n)] || null,
					network_name: orgNetwork?.short_name,
					network_id: orgNetwork?.network_id,
					role_id: sysRole?.role_id,
				})
			),
		})) || [];

	useEffect(() => {
		if (total >= 0) {
			setTotalUsers(total);
		}
	}, [total]);

	const onPage = ({ first }) => !loading && setOffset(first);

	const [createUserMutation, { loading: loadingCreateUser }] = useMutation(
		createUser,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.createUserSuccess));
				setOpenCreateUser(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenCreateUser(false);
			},
		}
	);

	function onSubmitUser(input) {
		createUserMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getUsers,
					variables: {
						input: {
							filter: { disabled_at: { isNull: true } },
							pagination: { limit: 10 },
							orderBy: { field: 'name', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>{t(usersPage.title)}</div>
				{hasPermission(operations, CREATE_USER) && (
					<div>
						<Button
							label={t(usersPage.create)}
							className='p-button'
							icon='pi pi-plus-circle'
							onClick={() => {
								setOpenCreateUser(true);
							}}
						/>
					</div>
				)}
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<Searchbar
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
							setOffset(0);
						}}
						placeholder={t(usersPage.searchPlaceholder)}
					/>
					<UsersTable
						value={usersMap}
						loading={data?.listBuses == null && loading}
						emptyMessage={t(usersTableError)}
						rows={10}
						total={totalUsers}
						onPage={onPage}
						first={offset}
						lazy
						operations={operations}
					/>
				</Card>
			</div>
			<UserDialog
				{...props}
				open={openCreateUser}
				setOpenDialog={setOpenCreateUser}
				onSubmitUser={onSubmitUser}
				loading={loadingCreateUser}
			/>
		</div>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Usuarios));
