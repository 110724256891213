import React, { useState, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { usersPage, dialog } from '../../configs/textos';
import * as projectAction from '../../store/actions/projectAction';
import UserNetworks from './UserNetworks';
import ActionBodyTemplate from '../../components/ActionBodyTemplate';
import UserDialog from './dialogs/UserDialog';
import { clientAuthCognito as client } from '../../client';
import { editUser, deleteUser } from '../../graphql/mutations';
import { getUsers } from '../../graphql/queries';
import { EDIT_USER } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';

const UsersTable = (props) => {
	const { operations } = props;
	const { t } = useTranslation();
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [user, setUser] = useState({});
	const toast = useRef(null);

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	const [updateUserMutation, { loading }] = useMutation(editUser, {
		client,
		onCompleted() {
			showSuccessToast(t(dialog.updateUserSuccess));
			setOpenDialog(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
			setOpenDialog(false);
		},
	});

	function onSubmitUser(input) {
		updateUserMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getUsers,
					variables: {
						input: {
							filter: { disabled_at: { isNull: true } },
							pagination: { limit: 10 },
							orderBy: { field: 'name', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	const [deleteUserMutation, { loading: loadingDeleteUser }] = useMutation(
		deleteUser,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.deleteUserSuccess));
				setOpenDeleteDialog(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDeleteDialog(false);
			},
		}
	);

	function onSubmitDeleteUser() {
		deleteUserMutation({
			variables: { input: { user_id: user.user_id } },
			refetchQueries: [
				{
					query: getUsers,
					variables: {
						input: {
							filter: { disabled_at: { isNull: true } },
							pagination: { limit: 10 },
							orderBy: { field: 'name', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	return (
		<>
			<Toast ref={toast} />
			<ResponsiveDataTable {...props}>
				<Column field='name' header={t(usersPage.name)} />
				<Column field='email' header={t(usersPage.email)} />
				<Column
					body={(rowData) => (
						<UserNetworks networks={rowData?.networks} {...props} />
					)}
					header={t(usersPage.networks)}
				/>
				{hasPermission(operations, EDIT_USER) && (
					<Column
						body={(row) => (
							<ActionBodyTemplate
								onDeleteClick={() => {
									setUser(row);
									setOpenDeleteDialog(true);
								}}
								onEditClick={() => {
									setOpenDialog(true);
									setUser(row);
								}}
							/>
						)}
					/>
				)}
			</ResponsiveDataTable>
			<UserDialog
				{...props}
				open={openDialog}
				setOpenDialog={setOpenDialog}
				onSubmitUser={onSubmitUser}
				loading={loading}
				user={user}
			/>
			<ConfirmDialog
				visible={openDeleteDialog}
				onHide={() => {
					if (!loadingDeleteUser) {
						setOpenDeleteDialog(false);
					}
				}}
				message={
					<>
						¿Está seguro de que desea eliminar el usuario <b>{user?.name}</b>?
					</>
				}
				header='Eliminar usuario'
				accept={() => onSubmitDeleteUser(user)}
				reject={() => setOpenDeleteDialog(false)}
				acceptLabel={t(dialog.delete)}
				rejectLabel={t(dialog.cancel)}
				rejectClassName='rejectButton'
				acceptClassName='acceptDelete'
				className='confirmDialog'
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(UsersTable));
