import { Storage } from 'aws-amplify';
import { PDF, JPG } from '../configs/constantes';

export function getExtension(fileUrl) {
	return fileUrl.toLowerCase().split('.').pop();
}

export default async function getFileURL(pathFile) {
	try {
		const resultGet = await Storage.get(pathFile, {
			level: 'public',
			download: true,
		});
		if (getExtension(pathFile) === 'pdf') {
			const blob = new Blob([resultGet.Body], { type: 'application/pdf' });
			return URL.createObjectURL(blob);
		}
		if (getExtension(pathFile) === 'jpg') {
			const blob = new Blob([resultGet.Body], { type: 'image/jpg' });
			return URL.createObjectURL(blob);
		}
		return URL.createObjectURL(resultGet.Body);
	} catch (err) {
		return null;
	}
}

export async function uploadFiles(files) {
	try {
		await Promise.all(
			files.map(async (fileToUpload) =>
				Storage.put(fileToUpload.file_url, fileToUpload.file, {
					level: 'public',
				})
			)
		);
	} catch (e) {
		console.log(e);
	}
}

export function getCertificateS3URL({
	customerEmail,
	networkId,
	certificateTypeId,
	certificateFileId,
	fileName,
}) {
	const timestamp = new Date().getTime();
	const extensionFile = getExtension(fileName);
	return `${customerEmail}/${networkId}/${certificateTypeId}-${certificateFileId}-${timestamp}.${extensionFile}`;
}

export function getFileExtensionsAllowed(fileType) {
	if (fileType === JPG) {
		return '.jpg';
	}
	if (fileType === PDF) {
		return '.pdf';
	}
	return '.jpg,.pdf';
}
