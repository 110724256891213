import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { dialogWalletBalance } from '../../../../configs/textos';

function DialogFooter({
	disabled,
	loading,
	onClickSaveHandler,
	onClickAcceptHandler,
	onClickModifyHandler,
	onClickCancelHandler,
	error,
	confirm,
}) {
	const { t } = useTranslation();
	if (!confirm) {
		return (
			<Button
				label={t(dialogWalletBalance.save)}
				icon='pi pi-check'
				disabled={disabled}
				className='botonDialog'
				onClick={onClickSaveHandler}
			/>
		);
	}
	return (
		<div className='p-d-flex p-jc-end'>
			<div>
				<Button
					label={t(dialogWalletBalance.cancel)}
					className='p-button-secondary'
					onClick={onClickCancelHandler}
				/>
			</div>
			<div>
				<Button
					label={
						!error
							? t(dialogWalletBalance.accept)
							: t(dialogWalletBalance.modify)
					}
					icon='pi pi-check'
					className='botonDialog'
					loading={loading}
					onClick={!error ? onClickAcceptHandler : onClickModifyHandler}
				/>
			</div>
		</div>
	);
}
export default withTranslation('translations')(DialogFooter);
