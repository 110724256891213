import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { noDocumentsRequired } from '../../configs/mensajes';
import { walletDefPage } from '../../configs/textos';
import translate from '../../helpers/Translate';

function certificatesTable(props) {
	const { i18n, certificates } = props;
	const { t } = useTranslation();

	return (
		<>
			<div className='p-page-title  p-mt-5'>
				{t(walletDefPage.certificates)}
			</div>
			<Card className='growable p-mb-3'>
				<ResponsiveDataTable
					{...props}
					value={certificates}
					emptyMessage={t(noDocumentsRequired)}
					rows={5}
					total={certificates?.length}
				>
					<Column
						field='name'
						header={t(walletDefPage.name)}
						body={(row) =>
							row?.name ? JSON.parse(row.name)[translate(i18n)] : '-'
						}
						style={{ width: '250px' }}
					/>
					<Column
						field='description'
						header={t(walletDefPage.description)}
						body={(row) => {
							if (row?.description) {
								return row.description.length > 100
									? `${JSON.parse(row.description)[translate(i18n)].substring(
											0,
											100
									  )}...`
									: JSON.parse(row.description)[translate(i18n)];
							}
							return '-';
						}}
					/>
				</ResponsiveDataTable>
			</Card>
		</>
	);
}
export default withTranslation('translations')(certificatesTable);
