import React, { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';

import TextAreaField from '../../components/TextAreaField';
import BlackListReasonsDropdown from './BlackListReasonsDropdown';
import BlackListUnblockReasonsDropdown from './BlackListUnblockReasonsDropdown';

import * as projectAction from '../../store/actions/projectAction';
import DialogFooter from '../../components/DialogFooter';
import { addClass, removeClass } from './helpers/Dialog';
import { dialogBlackList, button } from '../../configs/textos';
import { dateFormat } from '../../helpers/DateFormat';
import SingleData from './DatosClienteTable/SingleData';
import translate from '../../helpers/Translate';

const DialogBlackList = ({
	open,
	setOpenDialog,
	name,
	loading,
	onSubmitBlackList,
	title,
	customer,
	id,
	i18n,
	unblock,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	function onSubmit(data) {
		onSubmitBlackList({
			...data,
			...(customer ? { customer_id: id } : { wallet_id: id }),
			...(!customer && unblock
				? { wallet_black_list: unblock.wallet_black_list }
				: {}),
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) => {
		if (!unblock) {
			const field = customer
				? 'customer_black_list_type_id'
				: 'wallet_black_list_type_id';
			return (
				['description', field].every(
					(k) => data[k] != null && String(data[k]).replace(/\s/g, '') !== ''
				) || 'error'
			);
		}
		if (unblock) {
			const field = customer
				? 'customer_black_list_unblock_type_id'
				: 'wallet_black_list_unblock_type_id';
			return (
				['description_unblock', field].every(
					(k) => data[k] != null && String(data[k]).replace(/\s/g, '') !== ''
				) || 'error'
			);
		}
		return false;
	};

	return (
		<>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit, hasValidationErrors, form }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={title}
							visible={displayBasic}
							style={{ width: '500px' }}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset({});
							}}
							onShow={() => form.reset({})}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={
										!unblock?.description
											? t(dialogBlackList.save).toUpperCase()
											: t(button.unblock).toUpperCase()
									}
									onClickHandler={handleSubmit}
									loading={loading}
									className={classNames({
										'button-unblock': unblock,
									})}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5 p-px-4'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<>
												<div
													className={classNames(
														'p-col-12 p-d-flex p-mb-2 p-flex-column p-flex-lg-row',
														{
															'custom-field': unblock,
														}
													)}
												>
													<SingleData
														info={name}
														label={
															customer
																? t(dialogBlackList.client)
																: t(dialogBlackList.wallet)
														}
													/>
												</div>
											</>
											{!unblock?.description && (
												<>
													<BlackListReasonsDropdown
														id={
															customer
																? 'customer_black_list_type_id'
																: 'wallet_black_list_type_id'
														}
														label={t(dialogBlackList.reason)}
														placeholder={t('Seleccione')}
														customer={customer}
														i18n={i18n}
													/>
													<TextAreaField
														id='description'
														label={t(dialogBlackList.description)}
														placeholder={t(
															dialogBlackList.descriptionPlaceholder
														)}
													/>
												</>
											)}
											{unblock?.description && (
												<>
													<div className='p-col-12 p-mb-2 p-d-flex p-flex-column p-flex-lg-row custom-field'>
														<SingleData
															info={
																customer
																	? JSON.parse(
																			unblock.Com_customer_black_list_type.name
																	  )[translate(i18n)]
																	: JSON.parse(
																			unblock.Com_wallet_black_list_type.name
																	  )[translate(i18n)]
															}
															label={t(dialogBlackList.reason)}
														/>
													</div>
													<div className='p-col-12 p-mb-2 p-d-flex p-flex-column p-flex-lg-row custom-field'>
														<SingleData
															info={unblock.description}
															label={t(dialogBlackList.description)}
														/>
													</div>
													<div className='p-col-12 p-mb-2 p-d-flex p-flex-column p-flex-lg-row custom-field'>
														<SingleData
															info={dateFormat(unblock.from_date, 'DD/MM/YYYY')}
															label={t(dialogBlackList.date)}
														/>
													</div>
													<div className='p-col-12 p-mb-0 '>
														<h5 className='p-mb-0 '>DESBLOQUEAR</h5>
													</div>
													<div className='p-col-12 p-mb-0'>
														<hr className='p-mb-0 p-mt-0 width100' />
													</div>
													<BlackListUnblockReasonsDropdown
														id={
															customer
																? 'customer_black_list_unblock_type_id'
																: 'wallet_black_list_unblock_type_id'
														}
														label={t(dialogBlackList.reason)}
														placeholder={t('Seleccione')}
														customer={customer}
														i18n={i18n}
													/>
													<TextAreaField
														id='description_unblock'
														label={t(dialogBlackList.description)}
														placeholder={t(
															dialogBlackList.descriptionPlaceholder
														)}
													/>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DialogBlackList));
