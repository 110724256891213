import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../store/actions/projectAction';
import MovementsTable from './MovementsTable';
import useGetCustomerMovements from './hooks/useGetMovements';

const Movements = ({ global }) => {
	const { networkId } = global;
	const [inputQuery, setInputQuery] = useState({});
	const [refetchQuery, setRefetchQuery] = useState(false);
	const [offset, setOffset] = useState(0);
	const { t } = useTranslation();
	const [totalMovements, setTotalMovements] = useState(0);
	const toast = useRef(null);

	const { data, loading, error } = useGetCustomerMovements({
		offset,
		inputQuery,
		networkId,
		refetchQuery,
		setRefetchQuery,
	});
	const { items: movements = [], total } = data?.listNetworkMovements || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalMovements(total);
		}
	}, [total]);

	if (error) {
		showErrorToast(error?.message);
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onFilter(input) {
		if (JSON.stringify(input) === JSON.stringify(inputQuery) && !refetchQuery) {
			setRefetchQuery(true);
		}
		setOffset(0);
		setInputQuery(input);
	}

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>{t('Movimientos')}</div>
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable p-mt-3'>
					<MovementsTable
						movements={movements}
						title={t('Movimientos')}
						onFilter={onFilter}
						rows={10}
						total={totalMovements}
						totalRecords={totalMovements}
						onPage={onPage}
						first={offset}
						loading={loading}
						lazy
					/>
				</Card>
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Movements));
