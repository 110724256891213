import React from 'react';
import { DataTable } from 'primereact/datatable';

const ResponsiveDataTable = ({
	loading,
	value,
	rowClassName,
	children,
	emptyMessage,
	total,
	rows = 10,
	onPage,
	first,
	paginator,
	lazy,
	className,
}) => (
	<DataTable
		totalRecords={total}
		loading={loading}
		value={value}
		className={className}
		rowClassName={rowClassName}
		paginator={paginator !== false}
		responsiveLayout='scroll'
		lazy={lazy}
		paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
		emptyMessage={emptyMessage}
		rows={rows}
		onPage={onPage}
		first={first}
	>
		{children}
	</DataTable>
);

export default ResponsiveDataTable;
