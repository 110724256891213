import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import InputField from '../../../../components/InputField';
import SingleData from '../../DatosClienteTable/SingleData';
import { dialogWalletBalance } from '../../../../configs/textos';

function AmountField({ confirmState, amount, wallet }) {
	const { t } = useTranslation();

	if (!confirmState) {
		return (
			<InputField
				id='amount'
				label={t(dialogWalletBalance.amount)}
				placeholder={
					wallet?.unit_type_id === 1
						? t(dialogWalletBalance.amountMoneyPlaceholder)
						: t(dialogWalletBalance.amountTripPlaceholder)
				}
			/>
		);
	}
	return (
		<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
			<SingleData
				label={t(dialogWalletBalance.amount)}
				info={`${amount} ${wallet?.unit_type_short_name}`}
			/>
		</div>
	);
}
export default withTranslation('translations')(AmountField);
