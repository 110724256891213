import { useQuery } from '@apollo/client';

import { clientAuthSaldos as client } from '../../../client';
import { getMonederos } from '../../../graphql/queries';

const useGetClients = ({ offset, searchInput }) => {
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				pagination: { limit: 10, offset },
				search: {
					searchFields: ['wallet_id', 'wallet_serial_number'],
					searchTerm: searchInput || '',
				},
				orderBy: { field: 'wallet_id', direction: 'DESC' },
			},
		},
	};

	return useQuery(getMonederos, queryParams);
};

export default useGetClients;
