import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { clientAuthCognito as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getPocketDefInfo } from '../../graphql/queries';
import DatosPocket from './DatosPocket';
import WalletDefsTable from './WalletDefsTable';
import { pocketDef } from '../../configs/textos';

const PocketDefInfo = (props) => {
	const history = useHistory();
	const {
		global: {
			user: { pocket_def_id: pocketDefId, pocket_defs: pocketDefsLength },
		},
	} = props;
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else if (pocketDefsLength > 1) {
			history.push(pocketDef.url);
		}
	}, [pocketDefId]);

	const { data, loading } = useQuery(getPocketDefInfo, {
		client,
		variables: { pocketDefId },
	});

	if (loading) return <LoadingSpinner />;

	const pocket = data?.listCom_pocket_defs?.items[0] || {};
	const walletsDefs = pocket?.Com_wallet_def?.items || [];

	return (
		<div className='p-container'>
			<DatosPocket {...props} pocket={pocket} />
			<WalletDefsTable {...props} walletDefs={walletsDefs} />
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(PocketDefInfo);
