import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Field } from 'react-final-form';
import { useDebouncedCallback } from 'use-debounce';
import LoadingSpinner from './LoadingSpinner';

const FilterDropdown = ({
	id,
	placeholder,
	optionLabel,
	optionValue,
	options,
	loading,
	onFilter,
	onClear,
	filterMatchMode,
	debounceTimeout = 500,
	offsetIncrease = 20,
	maxOffset = 100,
	resetFilterOnHide = true,
}) => {
	const dropdownRef = useRef(null);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isFiltering, setIsFiltering] = useState(false);
	const [currentScrollHeight, setCurrentScrollHeight] = useState(0);
	const [filter, setFilter] = useState({
		search: '',
		offset: 0,
	});

	const handleScrollHeightChange = (scrollHeight, clientHeight) => {
		if (scrollHeight !== currentScrollHeight) {
			setCurrentScrollHeight(scrollHeight - clientHeight);
		}
	};

	const increaseOffset = () => {
		const increasedOffset = filter.offset + offsetIncrease;
		if (increasedOffset < maxOffset) {
			setFilter((prevFilter) => ({
				...prevFilter,
				offset: increasedOffset,
			}));
		}
	};

	const handleScrollEnd = (event) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const hasReachedScrollEnd = scrollTop + clientHeight >= scrollHeight - 1;
		if (hasReachedScrollEnd) {
			increaseOffset();
			handleScrollHeightChange(scrollHeight, clientHeight);
		}
	};

	const getOptionsRef = () =>
		dropdownRef?.current?.overlayRef?.current?.lastChild;

	const registerScrollEndEventHandler = () => {
		const optionsRef = getOptionsRef();
		if (optionsRef) {
			optionsRef.onscroll = handleScrollEnd;
		}
	};

	const registerClearEventHandler = () => {
		const clearIcon = document.getElementsByClassName(
			'p-dropdown-filter-clear-icon pi pi-times'
		);
		if (clearIcon && clearIcon[0]) {
			clearIcon[0].onclick = onClear;
		}
	};

	const resumeScrollHeight = useCallback(() => {
		const optionsRef = getOptionsRef();
		if (optionsRef) {
			optionsRef.scrollTo({
				top: currentScrollHeight,
				behavior: 'auto',
			});
		}
	}, [options]);

	const handleFilter = (event) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			search: event.filter,
			offset: 0,
		}));
		setIsFiltering(false);
	};
	const debouncedHandleFilter = useDebouncedCallback(
		handleFilter,
		debounceTimeout
	);

	useEffect(() => {
		onFilter(filter.search, filter.offset);
	}, [filter]);

	useEffect(() => {
		if (isFirstLoad && !loading) {
			setIsFirstLoad(false);
		}
	}, [loading]);

	useEffect(() => {
		registerScrollEndEventHandler();
	}, [filter.offset]);

	useEffect(() => {
		registerClearEventHandler();
	}, [filter.search]);

	useEffect(() => {
		resumeScrollHeight();
	}, [resumeScrollHeight]);

	if (isFirstLoad) {
		return <LoadingSpinner custom />;
	}

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-2'>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							options={options}
							optionLabel={optionLabel}
							optionValue={optionValue}
							placeholder={placeholder}
							resetFilterOnHide={resetFilterOnHide}
							onFilter={(event) => {
								setIsFiltering(true);
								debouncedHandleFilter(event);
							}}
							disabled={loading}
							optionDisabled={() => loading}
							ref={dropdownRef}
							onFocus={registerScrollEndEventHandler}
							showFilterClear
							filterMatchMode={filterMatchMode ?? 'contains'}
							filter
							className='filter-dropdown'
							emptyMessage={
								loading || isFiltering ? 'Cargando...' : 'No hay resultados'
							}
							emptyFilterMessage={
								loading || isFiltering ? 'Cargando...' : 'No hay resultados'
							}
							id={id}
						/>
					</div>
				</div>
			)}
		/>
	);
};

export default FilterDropdown;
