import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import AppTopbar from '../components/Layout/AppTopbar/index';
import * as projectAction from '../store/actions/projectAction';
import { getSysUserByNetwork } from '../graphql/queries';

function Appbar({ onToggleMenu, global, setUserInfo }) {
	const { networkId } = global;

	const { data } = useQuery(getSysUserByNetwork, {
		variables: { input: { network_id: networkId } },
	});

	useEffect(() => {
		if (data != null) setUserInfo(data.getSysUser);
	}, [data]);

	return <AppTopbar onToggleMenu={onToggleMenu} />;
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
