import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { cliente } from '../../configs/textos';
import AccountLink from './AccountLink';
import * as projectAction from '../../store/actions/projectAction';

const ClientsTable = (props) => {
	const { t } = useTranslation();

	function rowClass(data) {
		return {
			'row-blocked': data.in_blacklist,
		};
	}

	return (
		<ResponsiveDataTable {...props} rowClassName={rowClass}>
			<Column field='customer_id' header={t(cliente.table.idCliente)} />
			<Column
				field='smart_id'
				header={t(cliente.table.nDocument)}
				body={(rowData) => (rowData?.smart_id ? rowData.smart_id : '-')}
			/>
			{/* <Column
				field='in_blacklist'
				body={(rowData) =>
					rowData.in_blacklist ? t('Bloqueado') : t('Activo')
				}
				header={t(cliente.table.estado)}
			/> */}
			<Column
				body={(e) => (
					<AccountLink customerId={e.customer_id} label={t('Ver billetera')} />
				)}
			/>
		</ResponsiveDataTable>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(ClientsTable));
