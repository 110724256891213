import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';

import InputField from '../../../components/InputField';

import DialogFooter from '../../../components/DialogFooter';
import { addClass, removeClass } from '../../DatosCliente/helpers/Dialog';
import { validadorasPage, dialogClient } from '../../../configs/textos';
import validateEmptyFields from '../../../helpers/ValidateFields';
import BusesDropdown from './BusesDropdown';

const ValidadoraDialog = ({
	open,
	setOpenDialog,
	loading,
	onSubmitValidadora,
	networkId,
	validadora,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	function onSubmit(data) {
		const { serial_number: serialNumber, bus_id: busId, ...rest } = data;
		onSubmitValidadora({
			...rest,
			...(validadora ? { machine_id: validadora.machine_id } : {}),
			...(!validadora ? { network_id: networkId } : {}),
			...(busId ? { bus_id: busId } : {}),
			serial_number: Number(serialNumber),
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) => {
		if (!Number(data.serial_number)) {
			return 'error';
		}
		return (
			validateEmptyFields(['serial_number', 'mac', 'bus_id'], data) || 'error'
		);
	};

	return (
		<>
			<Form
				onSubmit={onSubmit}
				initialValues={{
					mac: validadora?.mac || null,
					bus_id: validadora?.bus_id || null,
					serial_number: validadora?.serial_number || null,
				}}
				validate={validate}
				render={({ handleSubmit, hasValidationErrors, form }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={
								!validadora
									? t(validadorasPage.create)
									: t(validadorasPage.edit)
							}
							visible={displayBasic}
							style={{ width: '450px' }}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset();
							}}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogClient.save)}
									onClickHandler={() => {
										handleSubmit();
										form.reset({});
									}}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<InputField
												id='serial_number'
												label={t(validadorasPage.serialNumber)}
												placeholder={t(validadorasPage.serialNumber)}
											/>
											<InputField
												id='mac'
												label={t(validadorasPage.mac)}
												placeholder={t(validadorasPage.mac)}
											/>
											<BusesDropdown
												id='bus_id'
												label={t(validadorasPage.asignBus)}
												placeholder={t(validadorasPage.code)}
												networkId={networkId}
												vehicleId={validadora?.vehicle_id}
											/>
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

export default withTranslation('translations')(ValidadoraDialog);
