import React from 'react';

const AppFooter = () => (
	<div className='layout-footer p-text-left' id='footer'>
		<span className='footer-text' style={{ marginRight: '5px' }}>
			Grupo Avanza (R), 2022. Todos los derechos reservados.
		</span>
	</div>
);

export default AppFooter;
