import React from 'react';
import { Button } from 'primereact/button';
import { CSVLink } from 'react-csv';
import translate from '../helpers/Translate';
// import { formatDateInLocalTimezone } from '../helpers/DateFormat';

export default function CSVExport({
	data,
	onClickHandler,
	filename,
	label,
	i18n,
	innerRef,
	loading,
}) {
	const updatedData = data.map((item) => {
		const stateName = JSON.parse(item.name)[translate(i18n)];

		const typeName = JSON.parse(item.name)[translate(i18n)];

		return {
			idMovement: item.wallet_transaction_id,
			erpcoTransactionId: item.transaction_id,
			type: typeName,
			amount: item.amount,
			description: item.description,
			date: item.date_time,
			balance: item.available_balance,
			state: stateName,
		};
	});

	return (
		<>
			<Button
				disabled={loading}
				className='export-csv p-button'
				onClick={onClickHandler}
				loading={loading}
				loadingoptions={{ position: 'left' }}
			>
				{label}
			</Button>
			<CSVLink
				data={updatedData}
				filename={filename}
				ref={innerRef}
				separator=';'
			/>
		</>
	);
}
