import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { login } from '../../../configs/textos';
import PasswordField from '../../../components/PasswordField';

function LoginForm({
	loading,
	error,
	onSubmitLogin,
	openForgotPasswordCodeForm,
	successChangePassword,
}) {
	const { t } = useTranslation();

	function validateFormData(data) {
		const errors = {};
		if (!data.email) {
			errors.email = t('Email is required.');
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
			errors.email = t('Invalid email address. E.g. example@email.com');
		}
		if (!data.password) {
			errors.password = t('Password is required.');
		}
		return errors;
	}

	function isFormFieldValid(meta) {
		return !!(meta.touched && meta.error);
	}

	function getFormErrorMessage(meta) {
		return (
			isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
		);
	}

	function onSubmit(data) {
		onSubmitLogin(data);
	}

	return (
		<>
			<div className='formTitle'>{t(login.title)}</div>
			<Form
				onSubmit={onSubmit}
				initialValues={{ email: '', password: '' }}
				validate={validateFormData}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} className='p-fluid'>
						<Field
							name='email'
							render={({ input, meta }) => (
								<div className='p-field'>
									<label
										htmlFor='email'
										className={classNames({
											'p-error': isFormFieldValid(meta),
										})}
									>
										<div>{t('Correo electrónico')}</div>
									</label>
									<InputText
										id='email'
										{...input}
										placeholder='ejemplo@correo.com'
										className={classNames('login-placeholder', {
											'p-invalid': isFormFieldValid(meta),
										})}
										disabled={loading}
									/>
									{getFormErrorMessage(meta)}
								</div>
							)}
						/>
						<PasswordField
							id='password'
							label={t(login.contraseña)}
							loading={loading}
							placeholder={t('Escribe la contraseña')}
						/>
						{error && <p className='error-message'>{t(error)}</p>}
						<Button
							type='submit'
							disabled={loading}
							loading={loading}
							loadingoptions={{ position: 'left' }}
							label={t(login.submit)}
							className='p-mt-2 buttonLogin '
						/>
					</form>
				)}
			/>
			<div className='p-d-flex p-jc-center'>
				<button
					onClick={openForgotPasswordCodeForm}
					type='button'
					className='p-d-flex recover-password-text'
				>
					{t(login.recoverPassword)}
				</button>
			</div>
			{successChangePassword && (
				<p className='success-message'>{t(login.success)}</p>
			)}
		</>
	);
}
export default withTranslation('translations')(LoginForm);
