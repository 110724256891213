import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Toast } from 'primereact/toast';
import { clientAuthSaldos as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
// import DatosClienteTable from './DatosClienteTable';
import WalletsClienteTable from './WalletsClienteTable';
// import CertificadosClienteTable from './CertificadosClienteTable';
import { getClienteWallet } from '../../graphql/queries';
import LoadingSpinner from '../../components/LoadingSpinner';
import MovementsTable from './MovementsTable';
import { cliente } from '../../configs/textos';
// import mapCertificatesResponse from '../../helpers/CertificatesMapper';

const DatosCliente = ({ global }) => {
	const { customerId } = useParams();
	const { networkId } = global;
	const { t } = useTranslation();
	const toast = useRef(null);
	const [errorState, setErrorState] = useState(null);

	const { data, loading, error } = useQuery(getClienteWallet, {
		client,
		fetchPolicy: 'network-only',
		variables: { customerId, networkId },
	});

	useEffect(() => {
		if (error) {
			setErrorState(error.message);
		}
	}, [error]);

	if (errorState) {
		showErrorToast(errorState);
		setErrorState(null);
	}

	if (data?.getCustomerData == null && loading) return <LoadingSpinner />;
	if (
		error?.graphQLErrors != null &&
		error.graphQLErrors.some(
			({ errorType }) => errorType === 'CustomerNotInNetworkError'
		)
	)
		return <Redirect to={cliente.url} />;

	// const customer = data?.getCustomerData;
	// const certificates =
	// 	data?.getCustomerCertificates?.Dem_certificate_category?.items;
	const wallets = data?.listCust_pockets?.items;
	const lastMovements = data?.listCom_wallet_transactions?.items;
	// const mapCertificates = mapCertificatesResponse(certificates);

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}
	return (
		<div>
			<div className='p-container'>
				<Toast ref={toast} />
				{!loading && (
					<>
						{/* <DatosClienteTable cliente={customer} /> */}
						<div className=''>
							<WalletsClienteTable wallet={wallets} customerId={customerId} />
							{/* <CertificadosClienteTable
								certificates={mapCertificates}
								cliente={customer}
							/> */}
							<MovementsTable
								movements={lastMovements}
								customerId={customerId}
								lastMovements
								title={t('Últimos movimientos')}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DatosCliente));
