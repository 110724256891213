/* Mensajes */

/* Mensajes de exito */
export const ass = 'Error al obtener los clientes';

/* Mensajes de error */
export const clienteError = 'Error al obtener los clientes';
export const clienteTablaError = 'No se encontraron clientes.';
export const monederoTablaError = 'No se encontraron monederos.';
export const certificadoError = 'Error al obtener los certificados';
export const certificadoTablaError = 'No se encontraron certificados.';
export const walletClienteTablaError = 'No se encontraron títulos virtuales.';
export const documentsError = 'Error al obtener los tipos de documentos';
export const movementTableError = 'No se encontraron movimientos';
export const busesError = 'Error al obtener los autobuses';
export const busesTableError = 'No se encontraron autobuses';
export const validadorasError = 'Error al obtener las validadoras';
export const validadoraTableError = 'No se encontraron validadoras';
export const pocketsDefError = 'Error al obtener los pockets virtuales';
export const pocketsDefTableError = 'No se encontraron pockets virtuales';
export const walletDefsTableError = 'No se encontraron wallets';
export const usersError = 'Error al obtener los usurios';
export const usersTableError = 'No se encontraron usuarios';
export const noDocumentsRequired =
	'No se requieren documentos para adquirir este título.';
export const documentsNetworkError = 'Error al obtener los documentos';
export const documentsNetworkTableError = 'No se encontraron documentos';
export const filesNotFound = 'No se encontraron archivos';
