import React from 'react';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { clientAuthCognito as client } from '../../../client';
import { listCertificateTypes } from '../../../graphql/queries';
import LoadingSpinner from '../../../components/LoadingSpinner';
import translate from '../../../helpers/Translate';

export default function CertificateTypeDropdown({ i18n, networkId }) {
	const { data, loading } = useQuery(listCertificateTypes, {
		client,
		variables: { networkId },
	});

	if (loading) {
		return <LoadingSpinner custom />;
	}

	const certificateTypes = data?.listDem_certificate_types?.items || [];
	const types = certificateTypes.map((type) => ({
		label: type?.category_multiple_types
			? `${JSON.parse(type?.certificate_category_name)[translate(i18n)]} (${
					JSON.parse(type?.name)[translate(i18n)]
			  })`
			: JSON.parse(type?.name)[translate(i18n)],
		value: type?.certificate_type_id,
	}));

	const typesOrdered = types.sort((a, b) => a.label.localeCompare(b.label));

	return (
		<Field
			name='certificate_type_id'
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-my-3 p-mr-3'>
					<div className='p-d-flex p-ai-center'>
						<Dropdown
							{...input}
							id='certificate_type_id'
							options={typesOrdered}
							optionLabel='label'
							placeholder='Documento'
							required
							className='movements-dropdown'
						/>
					</div>
				</div>
			)}
		/>
	);
}
