import React from 'react';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { clientAuthCognito as client } from '../../client';
import { listDenialTypes } from '../../graphql/queries';
import LoadingSpinner from '../../components/LoadingSpinner';
import translate from '../../helpers/Translate';

export default function DenialTypesDropdown({
	i18n,
	label,
	networkId,
	verticalStyle,
}) {
	const { data, loading } = useQuery(listDenialTypes, {
		client,
		variables: { networkId },
	});

	if (loading) {
		return <LoadingSpinner custom />;
	}

	const denialTypes = data?.listDem_certificate_denial_types?.items || [];
	const denialTypesInput = denialTypes.map((type) => ({
		label: JSON.parse(type?.name)[translate(i18n)],
		value: type?.certificate_denial_type_id,
	}));

	return (
		<Field
			name='certificate_denial_type_id'
			render={({ input }) => (
				<div className='p-ai-center p-my-3 p-mb-2'>
					<label
						htmlFor='certificate_denial_type_id'
						className='p-col-fixed p-text-bold custom-label-dialog'
					>
						{label}
					</label>
					<div className='p-ai-center p-ml-3 p-mt-2'>
						<Dropdown
							{...input}
							id='certificate_denial_type_id'
							options={denialTypesInput}
							optionLabel='label'
							placeholder='Seleccione...'
							required
							className={classNames('', {
								'w-350': verticalStyle,
							})}
						/>
					</div>
				</div>
			)}
		/>
	);
}
