import React from 'react';
import { Button } from 'primereact/button';
import { ReactComponent as MoneySVG } from '../../../../images/money.svg';
import {
	EDIT_WALLET,
	EDIT_BALANCE,
	BLOCK_WALLET,
} from '../../../../configs/operations';
import hasPermission from '../../../../helpers/HasPermission';

function BodyTemplate({
	onEditClick,
	onUpdateBalanceClick,
	onBlackListHandler,
	disabledUpdate,
	isInBlackList,
	operations,
	disabledButtons,
}) {
	return (
		<div className='p-d-flex p-jc-center p-ai-center'>
			{hasPermission(operations, BLOCK_WALLET) && (
				<div>
					<Button
						icon={!isInBlackList ? 'pi pi-lock' : 'pi pi-unlock'}
						className='icon-button'
						onClick={onBlackListHandler}
						disabled={disabledButtons}
					/>
				</div>
			)}
			{hasPermission(operations, EDIT_BALANCE) && (
				<div>
					<Button
						icon={<MoneySVG className='fill-icon' />}
						className='icon-button'
						onClick={onUpdateBalanceClick}
						disabled={disabledUpdate || disabledButtons}
					/>
				</div>
			)}
			{hasPermission(operations, EDIT_WALLET) && (
				<div>
					<Button
						icon='pi pi-pencil'
						className='icon-button'
						onClick={onEditClick}
						disabled={disabledButtons}
					/>
				</div>
			)}
		</div>
	);
}

export default BodyTemplate;
