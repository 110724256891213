import React from 'react';
import classNames from 'classnames';

export default function SingleData({ label, info, className }) {
	return (
		<>
			<div className={classNames('p-text-bold p-p-0 p-my-2', className)}>
				{label}
			</div>
			<div className='info-data p-p-0 text-secondary p-my-2'>{info}</div>
		</>
	);
}
