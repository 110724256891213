import React from 'react';
import { NavLink } from 'react-router-dom';
import LinkContent from './LinkContent';

export default function RenderLink({
	item,
	index,
	activeIndex,
	setActiveIndex,
}) {
	function onMenuItemClickLocal(i) {
		if (i === activeIndex) setActiveIndex(null);
		else setActiveIndex(index);
	}

	if (item.to) {
		return (
			<NavLink
				activeClassName='active-route'
				to={item.to}
				onClick={() => onMenuItemClickLocal(index)}
				exact
				target={item.target}
			>
				<LinkContent item={item} />
			</NavLink>
		);
	}
	return (
		<a
			href={item.url}
			onClick={() => onMenuItemClickLocal(index)}
			target={item.target}
		>
			<LinkContent item={item} />
		</a>
	);
}
