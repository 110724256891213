import React, { useState, useEffect } from 'react';

import { Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as projectAction from './store/actions/projectAction';

import Appbar from './Appbar';
import AppFooter from './components/Layout/AppFooter';

import Clientes from './pages/Clientes';
import Movimientos from './pages/DatosCliente/MovimientosClienteTable';
import PocketDefInfo from './pages/PocketDefInfo';
import Error from './pages/Error';
import DatosCliente from './pages/DatosCliente';
import Monederos from './pages/Monederos';
import Buses from './pages/Buses/index';
import Validadoras from './pages/Validadoras';
import PocketsDef from './pages/PocketsDef/index';
import WalletDef from './pages/WalletDef/index';
import Usuarios from './pages/Usuarios';
import Movements from './pages/Movements';
import Documents from './pages/Documents';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/layout.scss';
import './layout/App.scss';

import {
	error,
	menuItems,
	cliente,
	bus,
	portada,
	movimiento,
	perfilCliente,
	validadora,
	pocketDef,
	walletDef,
	usuarios,
	movimientos,
	monedero,
	documentos,
} from './configs/textos';
import {
	LIST_CUSTOMERS,
	LIST_POCKETS,
	BUSES,
	VALIDATORS,
	LIST_USERS,
	LIST_MOVEMENTS,
	LIST_MONEDERO,
	LIST_DOCUMENTS,
} from './configs/pages';
import Menu from './components/Layout/AppMenu/index';
// import PrivateRoute from './components/PrivateRoute';

const App = (props) => {
	const {
		global: {
			user: {
				pocket_defs: pocketDefsLength,
				pocket_def_id: pocketDefId,
				pages,
			},
		},
	} = props;

	const [initialPage, setInitialPage] = useState(1);

	useEffect(() => {
		setInitialPage(1);
	}, [pages]);

	const [visibleMenu, setVisibleMenu] = useState(false);

	const wrapperClass = classNames('layout-wrapper layout-static', {
		'layout-static-sidebar-inactive': !visibleMenu,
	});

	function getIndexComponent(page) {
		switch (page) {
			case 1:
				return Clientes;
			case 2:
				return PocketsDef;
			case 3:
				return Buses;
			case 4:
				return Validadoras;
			case 5:
				return Usuarios;
			case 6:
				return Movements;
			default:
				return Clientes;
		}
	}

	return (
		<div className={wrapperClass}>
			<Appbar onToggleMenu={() => setVisibleMenu(!visibleMenu)} />
			<Menu
				visible={visibleMenu}
				onHide={() => setVisibleMenu(false)}
				items={menuItems}
				pages={pages}
			/>
			<div className='layout-main'>
				<Route
					path={portada.url}
					exact
					component={getIndexComponent(initialPage)}
				/>
				<Route
					{...props}
					exact
					path={cliente.url}
					component={Clientes}
					page={LIST_CUSTOMERS}
				/>
				<Route {...props} exact path={bus.url} component={Buses} page={BUSES} />
				<Route
					{...props}
					exact
					path={validadora.url}
					component={Validadoras}
					page={VALIDATORS}
				/>
				<Route
					{...props}
					exact
					path={usuarios.url}
					component={Usuarios}
					page={LIST_USERS}
				/>
				<Route
					{...props}
					exact
					path={`${movimiento.url}/:customerId`}
					component={Movimientos}
					page={LIST_CUSTOMERS}
				/>
				<Route
					{...props}
					exact
					path={pocketDef.url}
					component={() =>
						pocketDefsLength > 1 ? (
							<PocketsDef />
						) : (
							<Redirect to={`${pocketDef.url}/${pocketDefId}`} />
						)
					}
					page={LIST_POCKETS}
				/>
				<Route
					{...props}
					exact
					path={`${pocketDef.url}/:pocketDefId`}
					component={PocketDefInfo}
					page={LIST_POCKETS}
				/>
				<Route
					{...props}
					exact
					path={`${walletDef.url}/:walletDefId`}
					component={WalletDef}
					page={LIST_POCKETS}
				/>
				<Route
					{...props}
					exact
					path={`${perfilCliente.url}/:customerId`}
					component={DatosCliente}
					page={LIST_CUSTOMERS}
				/>
				<Route
					{...props}
					exact
					path={monedero.url}
					component={Monederos}
					page={LIST_MONEDERO}
				/>
				<Route
					{...props}
					exact
					path={movimientos.url}
					component={Movements}
					page={LIST_MOVEMENTS}
				/>
				<Route
					{...props}
					exact
					path={documentos.url}
					component={Documents}
					page={LIST_DOCUMENTS}
				/>
				<Route exact path={error.url} component={Error} />
			</div>
			<AppFooter />
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(App));
