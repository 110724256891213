import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReasonDropdown from './ReasonDropdown';
import { dialogWalletBalance } from '../../../../configs/textos';

function ReasonField({ confirmState, setOperationBalance, i18n, reason }) {
	const [reasonName, setReasonName] = useState('');
	const { t } = useTranslation();

	if (!confirmState) {
		return (
			<ReasonDropdown
				label={t(dialogWalletBalance.reason)}
				placeholder={t(dialogWalletBalance.reasonPlaceholder)}
				i18n={i18n}
				reasonSelected={reason}
				id='wallet_transaction_type_id'
				setOperation={setOperationBalance}
				setReasonName={(name) => setReasonName(name)}
			/>
		);
	}
	return (
		<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
			<div className='p-col-12 p-lg-5 p-text-bold p-p-0'>
				{t(dialogWalletBalance.reason)}
			</div>
			<div className='p-col-12 p-lg-9 p-p-0 text-secondary'>{reasonName}</div>
		</div>
	);
}
export default withTranslation('translations')(ReasonField);
