import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getPocketsDef } from '../../../graphql/queries';

const useGetPockets = ({ networkId }) => {
	const queryParams = {
		client,
		variables: {
			input: {
				filter: { network_id: { eq: networkId } },
				orderBy: { field: 'name', direction: 'ASC' },
			},
		},
	};

	return useQuery(getPocketsDef, queryParams);
};

export default useGetPockets;
