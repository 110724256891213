import React from 'react';
import { NavLink } from 'react-router-dom';
import { perfilCliente } from '../../configs/textos';

const AccountLink = ({ customerId, label }) => (
	<NavLink
		className='p-datatable-column-button'
		to={`${perfilCliente.url}/${customerId}`}
		exact
	>
		{label}
	</NavLink>
);

export default AccountLink;
