import React from 'react';
import NetworksDropdown from './NetworksDropDown';
import UserDropdown from './UserDropdown';

export default function RightSide({ logOut, setOpenDialog, userEmail }) {
	return (
		<div className='p-text-right  p-d-flex p-ai-center p-jc-end'>
			<NetworksDropdown />
			<UserDropdown
				logOut={logOut}
				setOpenDialog={setOpenDialog}
				userEmail={userEmail}
			/>
		</div>
	);
}
