import React from 'react';
import { classNames } from 'primereact/utils';
import { Field } from 'react-final-form';
import { Password } from 'primereact/password';

export default function PasswordField({
	id,
	label,
	placeholder,
	loading,
	autoComplete,
}) {
	function isFormFieldValid(meta) {
		return !!(meta.touched && meta.error);
	}

	function getFormErrorMessage(meta) {
		return (
			isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
		);
	}
	return (
		<Field
			name={id}
			render={({ input, meta }) => (
				<div className='p-field p-mt-5'>
					<label
						htmlFor='password'
						className={classNames({
							'p-error': isFormFieldValid(meta),
						})}
					>
						{label}
					</label>
					<Password
						{...input}
						id={id}
						feedback={false}
						toggleMask
						placeholder={placeholder}
						autoComplete={autoComplete}
						className={classNames({
							'p-invalid': isFormFieldValid(meta),
						})}
						disabled={loading}
					/>
					{getFormErrorMessage(meta)}
				</div>
			)}
		/>
	);
}
