import React from 'react';
import { Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';

export default function InputField({
	id,
	label,
	placeholder,
	disabled,
	className,
	verticalStyle,
	classNameDialog,
	editDialog,
	classNameCreate,
}) {
	return (
		<Field
			name={id}
			render={({ input }) => (
				<div
					className={classNames('p-mb-3 ', {
						'p-d-flex': !verticalStyle,
					})}
				>
					<label
						htmlFor={id}
						className={classNames('p-col-fixed p-text-bold ', {
							'label-dialog': !className,
							'custom-label-dialog': className,
							'edit-document-dialog': editDialog,
							'create-document-dialog': classNameCreate,
						})}
					>
						{label}
					</label>
					<div
						className={classNames(
							'p-d-flex p-flex-column p-flex-wrap p-jc-center ',
							{
								'p-ml-2': verticalStyle,
								'p-pl-2 p-pt-2 create-custom-input': classNameCreate,
							}
						)}
					>
						<InputText
							id={id}
							{...input}
							placeholder={placeholder}
							required
							autoComplete='off'
							className={classNames('dialog-inputtext ', {
								'custom-document-dialog': classNameDialog,
								'edit-document-dialog p-mt-2': editDialog,
							})}
							disabled={disabled}
						/>
					</div>
				</div>
			)}
		/>
	);
}
