import React from 'react';
import parse from 'html-react-parser';
import { useTranslation, withTranslation } from 'react-i18next';
import SingleData from '../../../components/SingleData';
import { walletDefPage } from '../../../configs/textos';
import translate from '../../../helpers/Translate';

function WalletData({ walletDef, i18n }) {
	const { t } = useTranslation();
	return (
		<div className='p-d-flex p-flex-column p-flex-lg-row p-jc-between'>
			<div>
				<div className='p-d-flex p-ml-2 '>
					<SingleData
						label={t(walletDefPage.name)}
						info={
							walletDef?.name
								? JSON.parse(walletDef.name)[translate(i18n)]
								: '-'
						}
						className='walletdef-label'
					/>
				</div>
				<div className='p-d-flex p-ml-2 p-mt-1'>
					<SingleData
						label={t(walletDefPage.description)}
						info={parse(
							walletDef?.description
								? JSON.parse(walletDef.description)[translate(i18n)]
								: '-'
						)}
						className='walletdef-label'
					/>
				</div>
				<div className='p-d-flex p-ml-2 p-mt-1'>
					<SingleData
						label={t(walletDefPage.requisites)}
						info={parse(
							walletDef?.requisites
								? JSON.parse(walletDef.requisites)[translate(i18n)]
								: '-'
						)}
						className='walletdef-label'
					/>
				</div>
				<div className='p-d-flex p-ml-2 p-mt-1'>
					<SingleData
						label={t(walletDefPage.terms)}
						info={parse(
							walletDef?.terms_and_conditions
								? JSON.parse(walletDef.terms_and_conditions)[translate(i18n)]
								: '-'
						)}
						className='walletdef-label'
					/>
				</div>
			</div>
			<div>
				<img
					src={`data:image/png;base64,${walletDef.image}`}
					alt='walletDefImage'
					className='walletdef-image'
				/>
			</div>
		</div>
	);
}
export default withTranslation('translations')(WalletData);
