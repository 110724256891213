import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import SingleData from '../../components/SingleData';
import Title from '../../components/Title';
import { dateFormatUTC } from '../../helpers/DateFormat';
import { pocketDefInfoPage } from '../../configs/textos';
import translate from '../../helpers/Translate';

function DatosPocket({ i18n, pocket }) {
	const { t } = useTranslation();
	return (
		<>
			<div className='p-page-title'>{t(pocketDefInfoPage.title)}</div>
			<Card>
				<Title label={t(pocketDefInfoPage.data.toUpperCase())} />
				<div className='p-d-flex p-ml-2 '>
					<SingleData
						label={t(pocketDefInfoPage.name)}
						info={pocket?.name ? JSON.parse(pocket.name)[translate(i18n)] : '-'}
						className='pocketdef-label'
					/>
				</div>
				<div className='p-d-flex p-ml-2 p-mt-1'>
					<SingleData
						label={t(pocketDefInfoPage.description)}
						info={
							pocket?.description
								? JSON.parse(pocket.description)[translate(i18n)]
								: '-'
						}
						className='pocketdef-label'
					/>
				</div>
				<div className='p-d-flex p-mt-5'>
					<div className='half'>
						<Title label={t(pocketDefInfoPage.validity.toUpperCase())} />
						<div className='p-d-flex p-ml-2 '>
							<SingleData
								label={t(pocketDefInfoPage.init)}
								info={
									pocket?.init_date
										? dateFormatUTC(pocket.init_date, 'DD/MM/YYYY')
										: '-'
								}
								className='pocketdef-dates-label'
							/>
						</div>
						<div className='p-d-flex p-ml-2 '>
							<SingleData
								label={t(pocketDefInfoPage.end)}
								info={
									pocket?.end_date
										? dateFormatUTC(pocket.end_date, 'DD/MM/YYYY')
										: '-'
								}
								className='pocketdef-dates-label'
							/>
						</div>
					</div>
					<div className='half'>
						<Title label={t(pocketDefInfoPage.conditions.toUpperCase())} />
						<div className='p-d-flex p-ml-2 '>
							<div className='p-mr-6 p-text-bold p-p-0 '>
								{t(pocketDefInfoPage.minUnits)}
							</div>
							<div className='p-p-0 text-secondary'>
								{pocket?.minimum_units !== null
									? `${pocket.minimum_units} €`
									: '-'}
							</div>
						</div>
						<div className='p-d-flex p-ml-2 p-mt-3'>
							<div className='p-mr-6 p-text-bold p-p-0 '>
								{t(pocketDefInfoPage.maxUnits)}
							</div>
							<div className='p-p-0 text-secondary'>
								{pocket?.maximum_units !== null
									? `${pocket.maximum_units} €`
									: '-'}
							</div>
						</div>
					</div>
				</div>
			</Card>
		</>
	);
}

export default withTranslation('translations')(DatosPocket);
