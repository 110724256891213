import React from 'react';
import { Button } from 'primereact/button';
import { dialog } from '../../configs/textos';

function DialogFooter({
	editar,
	labelEdit,
	labelValidate,
	labelReject,
	labelRenovate,
	onClickEdit,
	onClickValidate,
	onClickReject,
	onClickRenovate,
	loading,
	state,
	loadingValidate,
	showCancel,
	onClickCancel,
	labelCancel,
	loadingDeny,
	disabledDeny,
	denying,
	labelSave,
	onClickRemove,
	onClickSaveEdit,
	loadingDeleteDocument,
	loadingUpdate,
	disabled,
	disabledSave,
	loadingRenovate,
	renovate,
}) {
	const hideButtonReject =
		showState(state, 'Denegado') ||
		showState(state, 'Caducado') ||
		showState(state, 'Próxima caducidad') ||
		denying;
	const hideButtonValidate =
		showState(state, 'Validado') ||
		showState(state, 'Caducado') ||
		showState(state, 'Próxima caducidad') ||
		showCancel;
	const hideButtonRenovate =
		showState(state, 'Validado') ||
		showState(state, 'Denegado') ||
		showState(state, 'Pendiente') ||
		renovate;

	function showState(stateStr, certificateState) {
		return stateStr === certificateState;
	}

	if (loading) {
		return <></>;
	}

	return (
		<>
			{!editar && (
				<div>
					{!showCancel && (
						<Button
							label={labelEdit}
							className='p-button-edit'
							onClick={onClickEdit}
							disabled={loadingValidate}
						/>
					)}
					<div className='p-d-flex p-jc-end'>
						{showCancel && (
							<Button
								label={labelCancel}
								className='p-button-cancel'
								onClick={onClickCancel}
								disabled={loadingDeny || loadingRenovate}
							/>
						)}
						<div hidden={hideButtonReject}>
							<Button
								label={labelReject}
								className='p-button-danger'
								onClick={onClickReject}
								disabled={loadingValidate}
							/>
						</div>
						{(denying || renovate) && (
							<Button
								label={labelSave}
								icon='pi pi-check'
								loading={loadingDeny || loadingRenovate}
								disabled={disabledDeny || disabledSave}
								className='botonDialog'
								onClick={denying ? onClickReject : onClickRenovate}
							/>
						)}
						<div hidden={hideButtonValidate}>
							<Button
								label={labelValidate}
								className='p-button-validate'
								onClick={onClickValidate}
								loading={loadingValidate}
							/>
						</div>
						<div hidden={hideButtonRenovate}>
							<Button
								label={labelRenovate}
								className='p-button-renovate'
								onClick={onClickRenovate}
								loading={loadingRenovate}
							/>
						</div>
					</div>
				</div>
			)}
			{editar && (
				<div className='p-d-flex p-jc-between'>
					<Button
						label={dialog.delete}
						className='p-button-danger'
						onClick={onClickRemove}
						loading={loadingDeleteDocument}
						disabled={loadingUpdate}
					/>
					<div>
						<Button
							label={dialog.cancel}
							className='p-button-secondary'
							onClick={onClickCancel}
							loading={loading}
							disabled={disabled || loadingDeleteDocument || loadingUpdate}
						/>
						<Button
							label={dialog.save}
							onClick={onClickSaveEdit}
							disabled={disabled || loadingUpdate || disabledSave}
							loading={loadingUpdate}
							icon='pi pi-check'
						/>
					</div>
				</div>
			)}
		</>
	);
}
export default DialogFooter;
