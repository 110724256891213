import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

function LinkContent({ item }) {
	const { t } = useTranslation();
	const submenuIcon = item.items && (
		<i className='pi pi-fw pi-angle-down menuitem-toggle-icon' />
	);

	return (
		<>
			<span>{t(item.label)}</span>
			{submenuIcon}
		</>
	);
}

export default withTranslation('translations')(LinkContent);
