import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Button } from 'primereact/button';
import CertificateTypeDropdown from './CertificateTypeDropdown';
import CertificateStateDropdown from './CertificateStateDropdown';

function Filterbar({ i18n, onFilter, networkId }) {
	const { t } = useTranslation();

	function onSubmit(data) {
		onFilter({
			...data,
		});
	}

	function validate(data) {
		if (Object.keys(data).length === 0) {
			return 'error';
		}
	}

	return (
		<div>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit, form, hasValidationErrors, values }) => (
					<form onSubmit={handleSubmit} className='p-col-12 p-p-0'>
						<div className='card movements-form'>
							<div className='p-mr-5'>
								<div className='font16 p-margin p-d-flex p-jc-between'>
									<div className='p-px-1 p-ml-2 p-pt-1 p-d-flex p-ai-center'>
										<CertificateTypeDropdown
											i18n={i18n}
											networkId={networkId}
										/>
										<CertificateStateDropdown i18n={i18n} />
									</div>
									<div className='p-d-flex p-ai-center'>
										<Button
											className='p-mr-2 p-button-secondary'
											onClick={() => {
												onSubmit('');
												form.reset();
											}}
										>
											{t('Limpiar').toUpperCase()}
										</Button>
										<Button
											onClick={() => onSubmit(values)}
											disabled={hasValidationErrors}
											className='apply-button'
										>
											{t('Aplicar').toUpperCase()}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</form>
				)}
			/>
		</div>
	);
}
export default withTranslation('translations')(Filterbar);
