import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getUsers } from '../../../graphql/queries';

const useGetUsers = ({ offset, searchInput }) => {
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				filter: { disabled_at: { isNull: true } },
				pagination: { limit: 10, offset },
				search: {
					searchFields: ['email'],
					searchTerm: searchInput || '',
				},
				orderBy: { field: 'name', direction: 'ASC' },
			},
		},
	};

	return useQuery(getUsers, queryParams);
};

export default useGetUsers;
