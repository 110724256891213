import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { login } from '../../../configs/textos';

function ForgotPasswordCode({ loading, error, onSubmitForgotCode }) {
	const { t } = useTranslation();

	function validateFormData(data) {
		const errors = {};
		const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		if (!data.email) {
			errors.email = t('Email is required.');
		} else if (!validEmail.test(data.email)) {
			errors.email = t('Invalid email address. E.g. example@email.com');
		}
		return errors;
	}

	function onSubmit(data) {
		onSubmitForgotCode(data.email);
	}

	return (
		<>
			<div className='formTitle'>{t(login.recoverPasswordTitle)}</div>
			<Form
				onSubmit={onSubmit}
				initialValues={{ email: '' }}
				validate={validateFormData}
				render={({ handleSubmit, hasValidationErrors }) => (
					<form onSubmit={handleSubmit} className='p-fluid'>
						<div className='forgot-password-intro p-mb-4'>
							{t(login.forgotPasswordIntro)}
						</div>
						<div className='forgot-password-intro p-mb-2'>{t(login.email)}</div>
						<Field
							name='email'
							render={({ input }) => (
								<div className='p-d-flex p-mb-3'>
									<div className='p-d-flex p-flex-column p-jc-center'>
										<InputText
											{...input}
											id='email'
											placeholder='example@correo.com'
											required
											autoComplete='off'
											className='email-inputtext'
										/>
									</div>
								</div>
							)}
						/>
						{error && <p className='error-message'>{t(error)}</p>}
						<Button
							type='submit'
							disabled={loading || hasValidationErrors}
							loading={loading}
							loadingoptions={{ position: 'left' }}
							label={t(login.recoverPasswordTitle)}
							className='p-mt-2 buttonLogin p-mt-3 '
						/>
					</form>
				)}
			/>
		</>
	);
}
export default withTranslation('translations')(ForgotPasswordCode);
