const mapFilter = (inputQuery, networkId, pagination) => {
	if (!inputQuery) {
		return {
			pagination,
			filter: {
				network_id: { eq: networkId },
			},
		};
	}

	const filters =
		Object.keys(inputQuery).length > 0
			? {
					...(inputQuery.certificate_type_id
						? {
								certificate_type_id: {
									eq: inputQuery.certificate_type_id,
								},
						  }
						: {}),
					...(inputQuery.named_customer_certificate_state_id
						? {
								named_customer_certificate_state_id: {
									eq: inputQuery.named_customer_certificate_state_id,
								},
						  }
						: {}),
			  }
			: '';

	return {
		pagination,
		filter: {
			network_id: { eq: networkId },
			...filters,
		},
	};
};

export default mapFilter;
