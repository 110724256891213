import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { Form } from 'react-final-form';
import imageCompression from 'browser-image-compression';
import InputField from '../../components/InputField';
import {
	addClass,
	removeClass,
	validateDocument,
} from '../DatosCliente/helpers/Dialog';
import { documentDialog, dialog } from '../../configs/textos';
import SimpleDialogFooter from '../../components/SimpleDialogFooter';
import { getCertificateDetails } from '../../graphql/queries';
import LoadingSpinner from '../../components/LoadingSpinner';
import translate from '../../helpers/Translate';
import {
	dateFormat,
	dateFormatUTC,
	dateUTC,
	startOfDayUTC,
	endOfDayUTC,
} from '../../helpers/DateFormat';
import StateField from './StateField';
import DialogFooter from './DialogFooter';
import validateEmptyFields from '../../helpers/ValidateFields';
import DenialTypesDropdown from './DenialTypesDropdown';
import DateField from '../../components/DateField';
import getFileURL, {
	uploadFiles,
	getCertificateS3URL,
	getExtension,
	getFileExtensionsAllowed,
} from '../../helpers/S3Service';
import TextAreaField from '../../components/TextAreaField';
import ReferenceDocument from './ReferenceDocument';
import { DOCUMENTS_CATEGORY } from '../../configs/constantes';
import Files from './Files';
import rotateImage from '../../helpers/rotateImage';

const DocumentDialog = ({
	global,
	i18n,
	toast,
	open,
	setOpenDialog,
	certificateId,
	customerId,
	customerEmail,
	onValidateCertificate,
	loadingValidateCertificate,
	onDenyCertificate,
	loadingDenyCertificate,
	denying,
	setDenying,
	directlyOpenEdit = false,
	openDeleteDocument,
	setOpenDeleteDocument,
	onSubmitDeleteDocument,
	loadingDeleteDocument,
	onRenovateSubmit,
	loadingRenovateCertificate,
	renovate,
	setRenovate,
	setLoadingRenovateCertificate,
	editar,
	setEditar,
	loadingUpdateCertificate,
	updateCertificado,
}) => {
	const { t } = useTranslation();

	const {
		user: { network_id: networkId },
	} = global;

	const renderFirstTime = useRef(false);
	const [displayBasic, setDisplayBasic] = useState(open);
	const [dateInit, setDateInit] = useState('');
	const [dateEnd, setDateEnd] = useState('');
	const [errorDates, setErrorDates] = useState('');
	const [files, setFiles] = useState([]);
	const [errorDocumentValue, setErrorDocumentValue] = useState(null);
	const [loadingFiles, setLoadingFiles] = useState(true);

	const [getCertificate, { data, loading, error }] = useLazyQuery(
		getCertificateDetails,
		{
			fetchPolicy: 'network-only',
			variables: {
				customerCertificateId: certificateId,
			},
		}
	);

	const certificateDetails = data?.getCustomerCertificateDetails || null;

	useEffect(() => {
		if (renderFirstTime.current) {
			if (
				!loadingValidateCertificate &&
				!loadingDenyCertificate &&
				!loadingRenovateCertificate &&
				!loadingUpdateCertificate
			) {
				getCertificate();
			}
		}
		renderFirstTime.current = true;
	}, [
		loadingValidateCertificate,
		loadingDenyCertificate,
		loadingRenovateCertificate,
		loadingUpdateCertificate,
	]);

	useEffect(() => {
		const initFiles = async () => {
			const filesReceived =
				data?.getCustomerCertificateDetails
					?.Cust_named_customer_certificate_file?.items;

			if (!loading && filesReceived) {
				setLoadingFiles(true);

				const filesWithObjURL = await mapFiles(filesReceived, renovate);
				setFiles(filesWithObjURL);

				setLoadingFiles(false);
			}
		};

		initFiles();
	}, [data, loading, renovate]);

	const mapFiles = async (filesToMap, isRenovate) => {
		const filesWithObjURL = [];

		const filesToMapOrdered = orderFilesByFileOrderAsc(filesToMap);

		/* eslint-disable no-await-in-loop */
		for (let i = 0; i < filesToMapOrdered.length; i += 1) {
			const file = filesToMapOrdered[i];
			filesWithObjURL.push({
				...file,
				urlToRender: isRenovate ? null : await getFileURL(file.file_url),
				rotation: 0,
				changed: false,
			});
		}
		return filesWithObjURL;
	};

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');

			getCertificate();
		} else {
			clearInputs();
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	useEffect(() => {
		if (open) {
			if (!renovate && certificateDetails) {
				setDateInit(dateUTC(certificateDetails?.init_date));
				setDateEnd(dateUTC(certificateDetails?.exp_date));
			} else {
				setDateInit(new Date());
				const endDate = new Date(dateInit).setMilliseconds(
					certificateDetails?.Dem_certificate_type?.period_count *
						certificateDetails?.Dem_certificate_type?.Md_period_type
							?.milliseconds
				);
				setDateEnd(new Date(endDate));
			}
		}
	}, [editar, loading, renovate]);

	let categoryName = '';
	if (certificateDetails?.category_multiple_types) {
		categoryName = certificateDetails?.Dem_certificate_type
			?.Dem_certificate_category.name
			? `${
					JSON.parse(
						certificateDetails?.Dem_certificate_type?.Dem_certificate_category
							.name
					)[translate(i18n)]
			  } (${
					JSON.parse(certificateDetails?.Dem_certificate_type?.name)[
						translate(i18n)
					]
			  })`
			: null;
	} else {
		categoryName = certificateDetails?.Dem_certificate_type?.name
			? JSON.parse(certificateDetails?.Dem_certificate_type?.name)[
					translate(i18n)
			  ]
			: null;
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(error.message);
	}

	function restoreDialogAsDefault() {
		if (!directlyOpenEdit) {
			setDisplayBasic(false);
			setDenying(false);
			setRenovate(false);
			setEditar(false);
		}
		setFiles([]);
		setOpenDialog(false);
	}

	function onSubmit(dataToSubmit) {
		if (denying) {
			onDenyCertificate(dataToSubmit);
		} else if (editar || directlyOpenEdit) {
			onSaveEdit(dataToSubmit);
		} else if (renovate) {
			onRenovate(dataToSubmit);
		} else {
			onValidateCertificate();
		}
	}

	const validate = (dataToValidate) => {
		if (denying) {
			return (
				validateEmptyFields(
					['certificate_denial_description', 'certificate_denial_type_id'],
					dataToValidate
				) || 'error'
			);
		}

		if ((editar || renovate) && certificateDetails?.requires_value) {
			if (
				!validateDocument(
					dataToValidate.value,
					JSON.parse(certificateDetails?.Dem_certificate_type?.name)[
						translate(i18n)
					]
				)
			) {
				setErrorDocumentValue('Número de documento no válido');
				return 'error';
			}

			if (!dataToValidate.value) {
				setErrorDocumentValue('Número de documento requerido');
				return 'error';
			}
		}

		setErrorDocumentValue(null);

		if (
			(renovate &&
				certificateDetails?.Dem_certificate_type?.Dem_certificate_file
					?.total !== files.length) ||
			(renovate &&
				certificateDetails?.Dem_certificate_type?.Dem_certificate_file?.total >
					0 &&
				files[0]?.file_url_temp == null)
		) {
			return 'error';
		}

		const errorFiles = files.filter((file) => file.error?.length > 0);
		if (errorFiles.length > 0) {
			return 'error';
		}
	};

	function clearInputs() {
		setDateEnd('');
		setDateInit('');
		setErrorDates('');
	}

	function onSaveEdit(dataForm) {
		onEdit(dataForm);
	}

	async function onEdit(dataForm) {
		const certificateFiles = [];
		const filesToS3 = [];
		files.forEach((e) => {
			const doUploadToS3 = e?.changed || e?.rotation !== 0;

			certificateFiles.push({
				named_customer_certificate_file_id:
					e?.named_customer_certificate_file_id,
				file_url: doUploadToS3 ? e.file_url_temp : e.file_url,
			});
			if (doUploadToS3) {
				filesToS3.push({ file_url: e.file_url_temp, file: e.file });
			}
		});

		await uploadFiles(filesToS3);

		updateCertificado({
			value: dataForm?.value || null,
			exp_date: endOfDayUTC(dateEnd),
			init_date: startOfDayUTC(dateInit),
			named_customer_certificate_id: certificateId,
			Cust_named_customer_certificate_files: certificateFiles,
		});
	}

	const resetFiles = async () => {
		const filesReset = await mapFiles(files);

		setFiles(filesReset);
	};

	async function onRenovate(dataForm) {
		setLoadingRenovateCertificate(true);
		onRenovateSubmit({
			customer_id: customerId,
			certificate_type_id:
				certificateDetails?.Dem_certificate_type?.certificate_type_id,
			exp_date: endOfDayUTC(dateEnd),
			init_date: startOfDayUTC(dateInit),
			files,
			...(certificateDetails?.requires_value ? { value: dataForm.value } : {}),
		});
	}

	const onSelectFile = async (file, id) => {
		const fileIndex = files.findIndex(
			(item) => item.named_customer_certificate_file_id === id
		);
		if (fileIndex !== -1) {
			const fileData = files[fileIndex];

			const extensionFileSelected = `.${getExtension(file.name)}`;
			const extensionsAllowed = getFileExtensionsAllowed(
				fileData?.Dem_certificate_file?.file_type
			).split(',');

			const fileNameToS3 = getCertificateS3URL({
				customerEmail,
				networkId,
				certificateTypeId:
					certificateDetails?.Dem_certificate_type?.certificate_type_id,
				certificateFileId: fileData?.Dem_certificate_file?.certificate_file_id,
				fileName: file.name,
			});

			if (file?.type === 'image/jpeg') {
				if (file?.size > 1000000) {
					const imageCompress = await imageCompression(file, {
						maxSizeMB: 1,
						maxWidthOrHeight: 1920,
					});
					file = new File([imageCompress], file.name, {
						type: 'image/jpeg',
					});
				}
			}

			files[fileIndex] = {
				...fileData,
				changed: true,
				urlToRender: file?.objectURL || URL.createObjectURL(file),
				rotation: 0,
				file,
				error: !extensionsAllowed.includes(extensionFileSelected)
					? 'Error'
					: null,
				file_type: fileData?.Dem_certificate_file?.file_type,
				certificate_file_id:
					fileData?.Dem_certificate_file?.certificate_file_id,
				file_url_temp: fileNameToS3,
			};

			setFiles([...files]);
		}
	};

	const onRotateImage = async (id) => {
		const fileIndex = files.findIndex(
			(item) => item.named_customer_certificate_file_id === id
		);
		if (fileIndex !== -1) {
			const fileData = files[fileIndex];

			const fileToRotate = fileData?.urlToRender;

			const { file: fileRotated, url: urlRotated } = await rotateImage(
				fileToRotate,
				90
			);

			const newRotation =
				fileData.rotation + 90 > 270 ? 0 : fileData.rotation + 90;

			const fileNameToS3 = getCertificateS3URL({
				customerEmail,
				networkId,
				certificateTypeId:
					certificateDetails?.Dem_certificate_type?.certificate_type_id,
				certificateFileId: fileData?.Dem_certificate_file?.certificate_file_id,
				fileName: fileRotated.name,
			});

			files[fileIndex] = {
				...fileData,
				file: fileRotated,
				urlToRender: urlRotated,
				rotation: newRotation,
				file_type: fileData?.Dem_certificate_file?.file_type,
				certificate_file_id:
					fileData?.Dem_certificate_file?.certificate_file_id,
				file_url_temp: fileNameToS3,
			};
			setFiles([...files]);
		}
	};

	const orderFilesByFileOrderAsc = (objectArray) =>
		objectArray?.slice()?.sort((x, y) => {
			if (
				x.Dem_certificate_file.file_order === y.Dem_certificate_file.file_order
			) {
				return 0;
			}
			if (
				x.Dem_certificate_file.file_order < y.Dem_certificate_file.file_order
			) {
				return -1;
			}
			return 1;
		});

	return (
		<>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={{
					certificate_category_name: categoryName,
					value: certificateDetails?.value || null,
					actDate: certificateDetails?.Cust_named_customer_certificate_operation
						?.timestamp
						? dateFormat(
								certificateDetails.Cust_named_customer_certificate_operation
									.timestamp,
								'DD/MM/YYYY'
						  )
						: null,
					initDate: certificateDetails?.init_date
						? dateFormatUTC(certificateDetails.init_date, 'DD/MM/YYYY')
						: null,
					expDate: certificateDetails?.exp_date
						? dateFormatUTC(certificateDetails.exp_date, 'DD/MM/YYYY')
						: null,
					certificate_denial_type_id: certificateDetails
						?.Cust_named_customer_certificate_operation
						?.Dem_certificate_denial_type?.name
						? JSON.parse(
								certificateDetails?.Cust_named_customer_certificate_operation
									?.Dem_certificate_denial_type?.name
						  )[translate(i18n)]
						: null,
					certificate_denial_description:
						certificateDetails?.Cust_named_customer_certificate_operation
							?.certificate_denial_description || null,
				}}
				render={({ handleSubmit, form, values, hasValidationErrors }) => (
					<form onSubmit={handleSubmit}>
						<Dialog
							header={
								/* eslint-disable no-nested-ternary */
								denying
									? t(documentDialog.denyTitle)
									: editar
									? t(documentDialog.editTitle)
									: renovate
									? t(documentDialog?.renovateTitle)
									: t(documentDialog.see)
							}
							visible={displayBasic && !openDeleteDocument}
							style={{ width: '760px' }}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset();
							}}
							contentStyle={{
								minHeight: certificateDetails?.value ? '412px' : '346px',
							}}
							contentClassName='p-pt-2 p-flex-wrap'
							footer={
								<DialogFooter
									editar={editar || directlyOpenEdit}
									labelEdit={t(documentDialog.edit)}
									onClickEdit={() => {
										setEditar(true);
									}}
									loading={loading}
									labelValidate={t(documentDialog.validate)}
									onClickValidate={() => {
										handleSubmit();
									}}
									loadingValidate={loadingValidateCertificate}
									labelReject={
										!denying ? t(documentDialog.deny) : t(documentDialog.accept)
									}
									onClickReject={() => {
										if (
											values?.certificate_denial_type_id &&
											values?.certificate_denial_description &&
											denying
										) {
											handleSubmit();
										} else {
											setDenying(true);
										}
									}}
									loadingDeny={loadingDenyCertificate}
									disabledDeny={denying && hasValidationErrors}
									labelRenovate={t(documentDialog.renovate)}
									onClickRenovate={() => {
										if (renovate) {
											handleSubmit();
										} else {
											setRenovate(true);
										}
									}}
									loadingRenovate={loadingRenovateCertificate}
									state={
										certificateDetails
											?.Cust_named_customer_certificate_operation
											?.Cust_named_customer_certificate_state?.name
											? JSON.parse(
													certificateDetails
														?.Cust_named_customer_certificate_operation
														?.Cust_named_customer_certificate_state?.name
											  )[translate(i18n)]
											: null
									}
									labelCancel={t(documentDialog.cancel)}
									onClickCancel={() => {
										if (editar || renovate) {
											form.reset();
											resetFiles();
										}
										if (directlyOpenEdit) {
											setOpenDialog(false);
										} else {
											setEditar(false);
											setDenying(false);
											setRenovate(false);
										}
									}}
									showCancel={denying || renovate}
									denying={denying}
									labelSave={t('Guardar')}
									loadingUpdate={loadingUpdateCertificate}
									onClickSaveEdit={() => handleSubmit()}
									onClickRemove={() => {
										setOpenDeleteDocument(true);
									}}
									disabledSave={
										errorDocumentValue || errorDates || hasValidationErrors
									}
									renovate={renovate}
								/>
							}
						>
							<Toast ref={toast} />
							{!editar && loading && !directlyOpenEdit && !renovate && (
								<LoadingSpinner custom />
							)}
							{!editar && !loading && !directlyOpenEdit && !renovate && (
								<div className='document-container'>
									<div
										hidden={
											certificateDetails?.Dem_certificate_type
												?.Dem_certificate_category.certificate_category_id ===
											DOCUMENTS_CATEGORY
										}
									>
										<ReferenceDocument
											fileUrl={certificateDetails?.document_id_url}
										/>
										<Divider className='divider-doc-reference' />
									</div>
									<div className='details-container'>
										<div className='p-col-6 p-pr-0'>
											{loading && <LoadingSpinner custom />}
											<StateField
												i18n={i18n}
												state={
													certificateDetails
														?.Cust_named_customer_certificate_operation
														?.Cust_named_customer_certificate_state?.name
												}
												label={t(documentDialog.state)}
											/>
											<InputField
												id='certificate_category_name'
												label={t(documentDialog.category)}
												disabled
												className
												verticalStyle
											/>
											{certificateDetails?.requires_value && (
												<InputField
													id='value'
													label={t(documentDialog.value)}
													disabled
													className
													verticalStyle
												/>
											)}
											<InputField
												id='actDate'
												label={t(documentDialog.actDate)}
												disabled
												className
												verticalStyle
											/>
											<InputField
												id='initDate'
												label={t(documentDialog.initDate)}
												disabled
												className
												verticalStyle
											/>
											<InputField
												id='expDate'
												label={t(documentDialog.expDate)}
												disabled
												className
												verticalStyle
											/>
											{((certificateDetails
												?.Cust_named_customer_certificate_operation
												?.Dem_certificate_denial_type?.name &&
												certificateDetails
													?.Cust_named_customer_certificate_operation
													?.certificate_denial_description) ||
												denying) && (
												<>
													{!denying && (
														<InputField
															id='certificate_denial_type_id'
															label={t(documentDialog.denyName)}
															disabled
															className
															verticalStyle
														/>
													)}
													{denying && (
														<DenialTypesDropdown
															id='certificate_denial_type_id'
															label={t(documentDialog.denyName)}
															disabled
															networkId={networkId}
															i18n={i18n}
															verticalStyle
														/>
													)}
													<TextAreaField
														id='certificate_denial_description'
														label={t(documentDialog.denyDescription)}
														disabled={!denying}
														verticalStyle
														className
														placeholder={t(
															documentDialog.descriptionPlaceholder
														)}
													/>
												</>
											)}
										</div>
										<div className='p-col-1'>
											<Divider layout='vertical' className='files-divider' />
										</div>
										<div className='p-col-5'>
											<>
												<span
													htmlFor={t(documentDialog.files)}
													className='p-mt-3 p-text-bold p-mt-0'
												>
													<div className='p-d-flex p-ai-center'>
														{t(documentDialog.files)}
													</div>
												</span>
												{open && loadingFiles ? (
													<LoadingSpinner custom />
												) : (
													<Files
														files={files}
														action='VIEW'
														onRotate={onRotateImage}
														i18n={i18n}
													/>
												)}
											</>
										</div>
									</div>
								</div>
							)}

							{(editar || directlyOpenEdit || renovate) && (
								<div className='document-container'>
									<div
										hidden={
											certificateDetails?.Dem_certificate_type
												?.Dem_certificate_category.certificate_category_id ===
											DOCUMENTS_CATEGORY
										}
									>
										<ReferenceDocument
											fileUrl={certificateDetails?.document_id_url}
										/>
										<Divider className='divider-doc-reference' />
									</div>
									<div className='details-container'>
										<div className='p-col-6 p-pr-0'>
											{loading && <LoadingSpinner custom />}
											{!renovate && (
												<StateField
													i18n={i18n}
													state={
														certificateDetails
															?.Cust_named_customer_certificate_operation
															?.Cust_named_customer_certificate_state?.name
													}
													label={t(documentDialog.state)}
												/>
											)}
											<InputField
												id='certificate_category_name'
												label={t(documentDialog.category)}
												disabled
												className
												verticalStyle
											/>
											{certificateDetails?.requires_value && (
												<InputField
													id='value'
													label={t(documentDialog.value)}
													editDialog
													verticalStyle
													disabled={
														loadingRenovateCertificate ||
														loadingUpdateCertificate
													}
												/>
											)}
											{errorDocumentValue && (
												<div className='p-mb-3 date-margin margintop-10px'>
													<small className='p-error'>
														{errorDocumentValue}
													</small>
												</div>
											)}
											<DateField
												t={t}
												onChangeEnd={(endDateF) => setDateEnd(endDateF)}
												onChangeInit={(initDate) => setDateInit(initDate)}
												dateEnd={dateEnd}
												labelDateEnd={t(documentDialog.endDate)}
												dateInit={dateInit}
												setErrorDates={setErrorDates}
												errorDates={errorDates}
												verticalStyle
												disabled={
													loadingRenovateCertificate || loadingUpdateCertificate
												}
											/>
										</div>
										<div className='p-col-1'>
											<Divider
												layout='vertical'
												style={{
													backgroundColor: '#D9D9D9',
													width: '1px',
												}}
											/>
										</div>
										<div className='p-col-5'>
											<>
												<span
													htmlFor={t(documentDialog.files)}
													className='p-mt-3 p-text-bold p-mt-0'
												>
													<div className='p-d-flex p-ai-center'>
														{t(documentDialog.files)}
													</div>
												</span>

												{open && loadingFiles ? (
													<LoadingSpinner custom />
												) : (
													<Files
														files={files}
														action={renovate ? 'CREATE' : 'UPDATE'}
														onRotate={onRotateImage}
														i18n={i18n}
														onSelect={onSelectFile}
														disabled={
															loadingUpdateCertificate ||
															loadingRenovateCertificate
														}
													/>
												)}
											</>
										</div>
									</div>
								</div>
							)}
						</Dialog>
						<Dialog
							visible={openDeleteDocument}
							onHide={() => setOpenDeleteDocument(false)}
							footer={
								<SimpleDialogFooter
									labelCancel={t(dialog.cancel)}
									labelSubmit={t(dialog.delete)}
									onClickCancel={() => {
										setOpenDeleteDocument(false);
										setOpenDialog(true);
									}}
									onClickSubmit={() => {
										onSubmitDeleteDocument();
										setEditar(false);
										setFiles([]);
									}}
									loading={loadingDeleteDocument}
								/>
							}
							header={t(dialog.headerDeleteDocument)}
							className='confirmDialogDocument'
						>
							{t(dialog.confirmDeleteDocument)}
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

export default withTranslation('translations')(DocumentDialog);
