import React from 'react';

export default function WalletDetail({ label, info }) {
	return (
		<>
			<div className='custom-width p-text-bold p-p-0 '>{label}</div>
			<div className='p-p-0 text-secondary'>{info}</div>
		</>
	);
}
