const mapFilter = (inputQuery, networkId, pagination) => {
	if (!inputQuery) {
		const inputFilter = {
			pagination,
			filter: {
				network_id: { eq: networkId },
			},
			orderBy: { field: 'date_time', direction: 'DESC' },
		};

		return inputFilter;
	}
	const endDate = inputQuery.end_date
		? { maxEq: `${inputQuery.end_date}` }
		: null;
	const initDate = inputQuery.init_date
		? { minEq: `${inputQuery.init_date}` }
		: null;
	const walletId = inputQuery.wallet_id
		? { eq: inputQuery.wallet_id }
		: undefined;
	const filters =
		Object.keys(inputQuery).length > 0
			? {
					...(inputQuery.wallet_transaction_type_id
						? {
								wallet_transaction_type_id: {
									eq: inputQuery.wallet_transaction_type_id,
								},
						  }
						: {}),
			  }
			: '';

	const inputFilter = {
		pagination,
		filter: {
			network_id: { eq: networkId },
			wallet_id: walletId,
			date_time: {
				...endDate,
				...initDate,
			},
			...filters,
		},
		orderBy: { field: 'date_time', direction: 'DESC' },
	};

	return inputFilter;
};

export default mapFilter;
