import React, { useState, useEffect } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { ReactComponent as Rotate } from '../../images/rotate.svg';
import { ReactComponent as ImagePlaceholder } from '../../images/imagePlaceholder.svg';

// action: VIEW - CREATE - UPDATE
export default function File({
	onRotate,
	url,
	title,
	fileId,
	action = 'VIEW',
	accept = 'image/*',
	disabled = false,
	extension,
	error = null,
	onSelect,
	showOpenOtherWindowIcon = true,
}) {
	const [loadingRotate, setLoadingRotate] = useState(false);

	useEffect(() => {
		setLoadingRotate(false);
	}, [url]);

	const renderFile = ({ src, extensionFile }) => {
		if (extensionFile === 'jpg') {
			return <img className='docPhoto' src={src} alt='photoDoc' />;
		}
		if (extensionFile === 'pdf') {
			return (
				<object data={src} type='application/pdf'>
					<embed src={src} type='application/pdf' />
				</object>
			);
		}
		return null;
	};

	return (
		<>
			<FileUpload
				accept={accept}
				chooseOptions={{
					icon: action === 'UPDATE' ? 'pi pi-pencil' : 'pi pi-upload',
					iconOnly: true,
					className: 'nobutton',
					style: { maxHeight: '30px' },
				}}
				className='p-mt-3 font-family-lato'
				disabled={disabled}
				headerTemplate={({ chooseButton }) => (
					<div>
						<div className='p-d-flex p-jc-between p-ai-center p-mr-3 p-mb-2'>
							<div>
								{title && (
									<span className='p-text-bold description-label-file'>
										{title}
									</span>
								)}
							</div>
							<div className='p-d-flex p-jc-center p-ai-center'>
								{action === 'VIEW' && showOpenOtherWindowIcon && (
									<a
										href={url}
										aria-label='Username'
										className='p-datatable-column-button icon-button'
										rel='noreferrer'
										target='_blank'
									>
										<div className='p-d-flex p-ai-center'>
											<i
												className='ri-external-link-fill'
												style={{ fontSize: '23px' }}
											/>
										</div>
									</a>
								)}
								{action !== 'VIEW' && action !== 'REFERENCE'
									? chooseButton
									: null}
								{action === 'UPDATE' && extension === 'jpg' && (
									<Button
										icon={<Rotate className='fill-icon' />}
										className='icon-button'
										disabled={disabled || loadingRotate}
										onClick={() => {
											setLoadingRotate(true);
											onRotate(fileId);
										}}
									/>
								)}
							</div>
						</div>
						{error && (
							<small className='p-error'>
								Tipo de archivo no permitido. Por favor, suba un archivo con
								extensión
								{accept
									.split(',')
									.map((el, i) => `${i > 0 ? ' o ' : ''} ${el.substring(1)}`)
									.join('')}
								.
							</small>
						)}
						{!error &&
							url &&
							renderFile({ src: url, extensionFile: extension })}
					</div>
				)}
				itemTemplate={() => null}
				emptyTemplate={action === 'CREATE' ? <ImagePlaceholder /> : null}
				key={fileId}
				onSelect={async (e) => {
					const file = e.originalEvent.target.files[0];
					onSelect(file, fileId);
				}}
			/>
		</>
	);
}
