export const initProjectGlovalData = () => async (dispatch) => {
	dispatch({
		type: 'initGlobal',
	});
};

export const setBreadCrumb = (values) => async (dispatch) => {
	dispatch({
		type: 'setBreadCrumb',
		dataT: values,
	});
};

export const setUserInfo = (values) => async (dispatch) => {
	dispatch({
		type: 'setUserInfo',
		dataT: values,
	});
};

export const setNetworkId = (values) => async (dispatch) => {
	localStorage.setItem('networkId', values)
	
	dispatch({
		type: 'setNetworkId',
		dataT: values,
	});
};
