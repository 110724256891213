import React from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { listRoles } from '../../../graphql/queries';
import { clientAuthCognito as client } from '../../../client';
import translate from '../../../helpers/Translate';

export default function RolesDropdown({ label, id, placeholder, i18n }) {
	const { data, loading } = useQuery(listRoles, {
		client,
	});

	if (loading) return <LoadingSpinner custom />;

	const response = data?.listSys_roles.items || [];

	const roles = response.map((item) => ({
		value: {
			role_id: item.role_id,
			role_name: JSON.parse(item.name)[translate(i18n)],
		},
		label: JSON.parse(item.name)[translate(i18n)],
	}));

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold'>
						{label}
					</label>
					<div className='p-d-flex p-ai-center '>
						<Dropdown
							{...input}
							id={id}
							placeholder={placeholder}
							options={roles}
							optionLabel='label'
							required
							className='small-dropdown'
						/>
					</div>
				</div>
			)}
		/>
	);
}
