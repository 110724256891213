import React, { useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import * as projectAction from '../../store/actions/projectAction';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import {
	documentsPage,
	perfilCliente,
	dialog,
	documentDialog,
} from '../../configs/textos';
import translate from '../../helpers/Translate';
import { dateFormat, dateFormatUTC } from '../../helpers/DateFormat';
import Filterbar from './Filterbar';
import CertificateState from '../../components/CertificateState';
import DocumentDialog from './DocumentDialog';
import { getDocuments } from '../../graphql/queries';
import {
	validateCertificate,
	denyCertificate as denyCertificateMutation,
	removeCustomerCertificate,
	createCertificateMutation,
	updateCustomerCertificate,
} from '../../graphql/mutations';
import { uploadFiles } from '../../helpers/S3Service';

const DocumentsTable = (props) => {
	const { t } = useTranslation();
	const toast = useRef(null);
	const toastMain = useRef(null);
	const { first } = props;
	const [openDialog, setOpenDialog] = useState(false);
	const [denying, setDenying] = useState(false);
	const [showCancelButton, setShowCancelButton] = useState(false);
	const [openDeleteDocument, setOpenDeleteDocument] = useState(false);
	const [customerId, setCustomerId] = useState(null);
	const [renovate, setRenovate] = useState(false);
	const [editar, setEditar] = useState(false);
	const [certificateId, setCertificateId] = useState(null);
	const [loadingRenovateCertificate, setLoadingRenovateCertificate] =
		useState(false);
	const {
		global: {
			user: { network_id: networkId },
		},
		i18n,
		onFilter,
	} = props;

	function onFilterHandler(data) {
		onFilter(data);
	}

	const [validateCertificateMutation, { loading: loadingValidateCertificate }] =
		useMutation(validateCertificate, {
			onCompleted() {
				showSuccessToast(t(dialog.validateCertificateSuccess));
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialog(false);
			},
		});

	function onValidateCertificate() {
		validateCertificateMutation({
			variables: {
				input: {
					named_customer_certificate_id: certificateId,
				},
			},
			refetchQueries: [
				{
					query: getDocuments,
					variables: {
						inputFilter: {
							filter: {
								network_id: { eq: networkId },
							},
							pagination: {
								limit: 10,
								offset: first,
							},
						},
					},
				},
			],
		});
	}

	const [denyCertificate, { loading: loadingDenyCertificate }] = useMutation(
		denyCertificateMutation,
		{
			onCompleted() {
				showSuccessToast(t(dialog.denyCertificateSuccess));
				setDenying(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialog(false);
				setDenying(false);
			},
		}
	);

	function onDenyCertificate(dataToSubmit) {
		denyCertificate({
			variables: {
				input: {
					named_customer_certificate_id: certificateId,
					certificate_denial_type_id: dataToSubmit.certificate_denial_type_id,
					certificate_denial_description:
						dataToSubmit.certificate_denial_description,
				},
			},
			refetchQueries: [
				{
					query: getDocuments,
					variables: {
						inputFilter: {
							filter: {
								network_id: { eq: networkId },
							},
							pagination: {
								limit: 10,
								offset: first,
							},
						},
					},
				},
			],
		});
	}

	const [deleteDocumentMutation, { loading: loadingDeleteDocument }] =
		useMutation(removeCustomerCertificate, {
			onCompleted() {
				showSuccessToast(t(dialog.deleteDocumentSuccess));
				setOpenDialog(false);
				setOpenDeleteDocument(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialog(false);
				setOpenDeleteDocument(false);
			},
		});

	function onSubmitDeleteDocument() {
		deleteDocumentMutation({
			variables: {
				input: {
					named_customer_certificate_id: certificateId,
				},
			},
			refetchQueries: [
				{
					query: getDocuments,
					variables: {
						inputFilter: {
							filter: {
								network_id: { eq: networkId },
							},
							pagination: {
								limit: 15,
								offset: 0,
							},
						},
					},
				},
			],
		});
	}

	async function renovateCertificado(dataToSubmit) {
		setLoadingRenovateCertificate(true);
		const { files, ...rest } = dataToSubmit;
		const filesToS3 = [];
		const certificateFiles = [];
		files.map(
			({ certificate_file_id: certificateFileId, file_url: fileURL, file }) => {
				filesToS3.push({ file_url: fileURL, file });
				certificateFiles.push({
					file_url: fileURL,
					certificate_file_id: certificateFileId,
				});
				return '';
			}
		);
		await uploadFiles(filesToS3);
		renovateCertificateMutation({
			variables: {
				input: {
					...rest,
					files: certificateFiles,
				},
			},
			refetchQueries: [
				{
					query: getDocuments,
					variables: {
						inputFilter: {
							filter: {
								network_id: { eq: networkId },
							},
							pagination: {
								limit: 15,
								offset: 0,
							},
						},
					},
				},
			],
		});
	}

	const [renovateCertificateMutation] = useMutation(createCertificateMutation, {
		onCompleted(data) {
			showSuccessToast(t(documentDialog.renovateCertificateSuccess));
			setRenovate(false);
			setCertificateId(
				data?.createCustomerCertificate?.named_customer_certificate_id
			);
			setLoadingRenovateCertificate(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
		},
	});

	function updateCertificado(input) {
		updateCertificateMutation({
			variables: {
				input,
			},
			refetchQueries: [
				{
					query: getDocuments,
					variables: {
						inputFilter: {
							filter: {
								network_id: { eq: networkId },
							},
							pagination: {
								limit: 15,
								offset: 0,
							},
						},
					},
				},
			],
		});
	}

	const [updateCertificateMutation, { loading: loadingUpdateCertificate }] =
		useMutation(updateCustomerCertificate, {
			onCompleted() {
				showSuccessToast(t(documentDialog.updateCertificateSuccess));
				setEditar(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
			},
		});

	function showSuccessToast(message) {
		toastMain?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toastMain?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	return (
		<>
			<Toast ref={toastMain} />
			<Filterbar i18n={i18n} onFilter={onFilterHandler} networkId={networkId} />
			<ResponsiveDataTable {...props}>
				<Column
					field='certificate_type_name'
					header={t(documentsPage.document)}
					body={(rowData) =>
						rowData.category_multiple_types
							? `${
									JSON.parse(rowData?.certificate_category_name)[
										translate(i18n)
									]
							  } (${
									JSON.parse(rowData?.certificate_type_name)[translate(i18n)]
							  })`
							: JSON.parse(rowData.certificate_type_name)[translate(i18n)]
					}
				/>
				<Column
					field='exp_date'
					header={t(documentsPage.expDate)}
					body={(row) => dateFormatUTC(row.exp_date, 'DD/MM/YYYY')}
				/>
				<Column
					field='timestamp'
					header={t(documentsPage.actDate)}
					body={(row) => dateFormat(row.timestamp, 'DD/MM/YYYY')}
				/>
				<Column
					field='customer_certificate_state_name'
					header={t(documentsPage.state)}
					body={(row) => (
						<CertificateState
							state={
								JSON.parse(row?.customer_certificate_state_name)[
									translate(i18n)
								]
							}
							webColor={row?.customer_certificate_state_web_color}
							textColor={row?.customer_certificate_state_text_color}
						/>
					)}
				/>
				<Column field='email' header={t(documentsPage.email)} />
				<Column
					body={(row) => (
						<div className='p-d-flex p-ai-center'>
							<Button
								icon='ri-eye-line'
								className='icon-button p-mr-3'
								style={{ fontSize: '28px' }}
								onClick={() => {
									setOpenDialog(true);
									setCertificateId(row?.named_customer_certificate_id);
									setCustomerId(row.customer_id);
								}}
							/>
							<NavLink
								className='p-datatable-column-button icon-button'
								to={`${perfilCliente.url}/${row?.customer_id}`}
								exact
							>
								<div className='p-d-flex p-ai-center'>
									<i
										className='ri-account-box-line'
										style={{ fontSize: '28px' }}
									/>
								</div>
							</NavLink>
						</div>
					)}
				/>
			</ResponsiveDataTable>
			<DocumentDialog
				{...props}
				toast={toast}
				open={openDialog}
				setOpenDialog={setOpenDialog}
				certificateId={certificateId}
				onValidateCertificate={onValidateCertificate}
				loadingValidateCertificate={loadingValidateCertificate}
				onDenyCertificate={onDenyCertificate}
				loadingDenyCertificate={loadingDenyCertificate}
				setDenying={setDenying}
				denying={denying}
				setShowCancelButton={setShowCancelButton}
				showCancelButton={showCancelButton}
				setOpenDeleteDocument={setOpenDeleteDocument}
				openDeleteDocument={openDeleteDocument}
				onSubmitDeleteDocument={onSubmitDeleteDocument}
				loadingDeleteDocument={loadingDeleteDocument}
				customerId={customerId}
				onRenovateSubmit={renovateCertificado}
				loadingRenovateCertificate={loadingRenovateCertificate}
				renovate={renovate}
				setRenovate={setRenovate}
				setLoadingRenovateCertificate={setLoadingRenovateCertificate}
				loadingUpdateCertificate={loadingUpdateCertificate}
				updateCertificado={updateCertificado}
				editar={editar}
				setEditar={setEditar}
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DocumentsTable));
