import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { walletDefsTableError } from '../../configs/mensajes';
import {
	pocketsPage,
	walletDef,
	pocketDefInfoPage,
} from '../../configs/textos';
import { dateFormatUTC } from '../../helpers/DateFormat';
import translate from '../../helpers/Translate';

function walletDefsTable(props) {
	const { i18n, walletDefs } = props;
	const { t } = useTranslation();

	return (
		<>
			<div className='p-page-title  p-mt-5'>
				{t(pocketDefInfoPage.titleWallet)}
			</div>
			<Card className='growable p-mb-3'>
				<ResponsiveDataTable
					{...props}
					value={walletDefs}
					emptyMessage={t(walletDefsTableError)}
					rows={10}
					total={walletDefs?.length}
				>
					<Column
						field='name'
						header={t(pocketsPage.name)}
						body={(row) =>
							row?.name ? JSON.parse(row.name)[translate(i18n)] : '-'
						}
						style={{ width: '170px' }}
					/>
					<Column
						field='description'
						header={t(pocketsPage.description)}
						body={(row) =>
							row?.description && row.description.length > 65
								? `${JSON.parse(row.description)[[translate(i18n)]]?.substring(
										0,
										65
								  )}...`
								: JSON.parse(row.description)[translate(i18n)]
						}
						style={{ width: '400px' }}
					/>
					<Column
						field='init_date'
						header={t(pocketsPage.initDate)}
						body={(row) =>
							row?.init_date ? dateFormatUTC(row.init_date, 'DD/MM/YYYY') : '-'
						}
						className='width120'
					/>
					<Column
						field='end_date'
						header={t(pocketsPage.endDate)}
						body={(row) =>
							row?.end_date ? dateFormatUTC(row.end_date, 'DD/MM/YYYY') : '-'
						}
						className='width120'
					/>
					<Column
						body={(row) => (
							<NavLink
								className='p-datatable-column-button'
								to={`${walletDef.url}/${row?.wallet_def_id}`}
								exact
							>
								{t(pocketsPage.seeTitle)}
							</NavLink>
						)}
						className='width120'
					/>
				</ResponsiveDataTable>
			</Card>
		</>
	);
}
export default withTranslation('translations')(walletDefsTable);
