import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';
import InputField from '../../../components/InputField';
import DialogFooter from '../../../components/DialogFooter';
import { addClass, removeClass } from '../../DatosCliente/helpers/Dialog';
import { busesPage, dialogClient } from '../../../configs/textos';
import validateEmptyFields from '../../../helpers/ValidateFields';
import ServiceClassDropdown from './ServiceClassDropdown';

const BusDialog = ({
	open,
	setOpenDialog,
	loading,
	onSubmitBus,
	networkId,
	bus,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	function onSubmit(data) {
		onSubmitBus({
			...data,
			...(!bus ? { active: true } : {}),
			...(!bus ? { network_id: networkId } : {}),
			...(bus ? { bus_id: bus.bus_id } : {}),
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) =>
		validateEmptyFields(['plate', 'vehicle_id', 'service_class_id'], data) ||
		'error';

	return (
		<>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={{
					plate: bus?.plate || null,
					vehicle_id: bus?.vehicle_id || null,
					service_class_id: bus?.service_class_id || null,
				}}
				render={({ handleSubmit, hasValidationErrors, form }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={!bus ? t(busesPage.create) : t(busesPage.edit)}
							visible={displayBasic}
							style={{ width: '450px' }}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset();
							}}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogClient.save)}
									onClickHandler={() => {
										handleSubmit();
										form.reset({});
									}}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<InputField
												id='plate'
												label={t(busesPage.plate)}
												placeholder={t(busesPage.plate)}
											/>
											<InputField
												id='vehicle_id'
												label={t(busesPage.code)}
												placeholder={t(busesPage.number)}
											/>
											<ServiceClassDropdown
												label={t(busesPage.service)}
												id='service_class_id'
												placeholder={t(busesPage.serviceClass)}
												networkId={networkId}
												serviceClassId={bus?.service_class_id}
											/>
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

export default withTranslation('translations')(BusDialog);
