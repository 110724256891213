import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { login } from '../../../configs/textos';
import PasswordField from '../../../components/PasswordField';
import RequisitesPassword from '../components/RequisitesPassword';
import {
	checkLength,
	checkUpperLoweCase,
	checkNumbersAndSpecialChars,
} from '../../../helpers/CheckPassword';

function NewPasswordForm({
	loading,
	error,
	onSubmitNewPassword,
	forgotPassword,
}) {
	const { t } = useTranslation();
	const [checkedMinLength, setCheckedMinLength] = useState(false);
	const [checkedLetters, setCheckedLetters] = useState(false);
	const [checkedSpecialChars, setCheckedSpecialChars] = useState(false);

	function validateFormData(data) {
		const errors = {};

		if (forgotPassword && !data.code) {
			errors.code = t(login.codeRequired);
		}
		if (!data.newPassword) {
			errors.newPassword = t(login.passwordRequired);
		}
		if (!data.repeatPassword) {
			errors.repeatPassword = t(login.passwordRequired);
		}
		if (
			data.newPassword &&
			data.repeatPassword &&
			data.repeatPassword !== data.newPassword
		) {
			errors.repeatPassword = t(login.passwordDifferent);
		}
		setCheckedMinLength(checkLength(data.newPassword));
		setCheckedLetters(checkUpperLoweCase(data.newPassword));
		setCheckedSpecialChars(checkNumbersAndSpecialChars(data.newPassword));
		if (!checkedMinLength || !checkedLetters || !checkedSpecialChars) {
			errors.noChecked = true;
		}
		return errors;
	}

	function onSubmit(data) {
		const { code, ...restData } = data;
		onSubmitNewPassword({
			...restData,
			...(forgotPassword ? { code } : {}),
		});
	}

	return (
		<>
			<div className='formTitle'>{t(login.newPassword)}</div>
			{forgotPassword && (
				<div className='forgot-password-intro p-mb-4'>
					{t(login.forgotPasswordCodeIntro)}
				</div>
			)}
			<Form
				onSubmit={onSubmit}
				initialValues={{ newPassword: '', repeatPassword: '', code: '' }}
				validate={validateFormData}
				render={({ handleSubmit, hasValidationErrors, values }) => (
					<form
						onSubmit={handleSubmit}
						className='p-fluid'
						autoComplete='new-password'
					>
						{forgotPassword && (
							<>
								<div className='forgot-password-code p-mb-2'>
									{t(login.code)}
								</div>
								<Field
									name='code'
									render={({ input, meta }) => (
										<div className='p-d-flex'>
											<div className='p-d-flex p-flex-column p-jc-center'>
												<InputText
													{...input}
													id='code'
													placeholder={t(login.code)}
													required
													autoComplete='none'
													className='code-inputtext'
												/>
												{!!(meta.touched && meta.error) && (
													<small className='p-mt-2 p-error'>{meta.error}</small>
												)}
											</div>
										</div>
									)}
								/>
							</>
						)}
						<PasswordField
							id='newPassword'
							label={t(login.newPassword)}
							placeholder={t(login.newPassword)}
							loading={loading}
							autoComplete='new-password'
						/>
						<PasswordField
							id='repeatPassword'
							label={t(login.repeatPassword)}
							placeholder={t(login.confirmPassword)}
							loading={loading}
							autoComplete='new-password'
						/>
						<RequisitesPassword
							password={values?.newPassword}
							checkLetters={checkedLetters}
							checkMinLength={checkedMinLength}
							checkSpecialChars={checkedSpecialChars}
						/>
						{error && <p className='error-message'>{error}</p>}
						<Button
							type='submit'
							disabled={loading || hasValidationErrors}
							loading={loading}
							loadingoptions={{ position: 'left' }}
							label={t(login.accept)}
							className='p-mt-2 buttonLogin '
						/>
					</form>
				)}
			/>
		</>
	);
}
export default withTranslation('translations')(NewPasswordForm);
