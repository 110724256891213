import React from 'react';
import File from './File';
import translate from '../../helpers/Translate';
import {
	getFileExtensionsAllowed,
	getExtension,
} from '../../helpers/S3Service';

export default function Files({
	files,
	onRotate,
	i18n,
	action,
	onSelect,
	disabled,
}) {
	const getTitle = (descriptionJSON) =>
		JSON.parse(descriptionJSON)[translate(i18n)];

	return (
		<>
			{files.map(
				({
					Dem_certificate_file: certificateFile,
					file_url: fileUrl,
					named_customer_certificate_file_id: fileId,
					urlToRender,
					file_url_temp: fileUrlTemp,
					file: fileTemp,
					error,
				}) => (
					<File
						key={`file_${fileUrl}`}
						url={urlToRender}
						onRotate={onRotate}
						title={getTitle(certificateFile?.description)}
						fileId={fileId}
						action={action}
						accept={getFileExtensionsAllowed(certificateFile?.file_type)}
						fileType={certificateFile?.file_type}
						disabled={disabled}
						extension={getExtension(fileTemp ? fileUrlTemp : fileUrl)}
						onSelect={onSelect}
						error={error}
					/>
				)
			)}
		</>
	);
}
