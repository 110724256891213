import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getMovements } from '../../../graphql/queries';
import mapFilter from './mapFilter';

const useGetMovements = ({
	offset,
	inputQuery,
	networkId,
	refetchQuery,
	setRefetchQuery,
}) => {
	const pagination = { limit: 15, offset };
	const inputFilter = mapFilter(inputQuery, networkId, pagination);

	const queryParams = {
		client,
		variables: {
			inputFilter,
		},
	};

	const { data, loading, error, refetch } = useQuery(getMovements, queryParams);
	if (refetchQuery) {
		refetch(queryParams.variables);
		setRefetchQuery(false);
	}

	return { data, loading, error };
};

export default useGetMovements;
