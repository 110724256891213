import React from 'react';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { listNetworks } from '../../../graphql/queries';
import { clientAuthCognito as client } from '../../../client';

export default function NetworksDropdown({ label, id, placeholder }) {
	const { data, loading } = useQuery(listNetworks, {
		client,
	});

	if (loading) return <LoadingSpinner custom />;

	const response = data?.listOrg_networks.items || [];

	const networks = response.map((item) => ({
		value: { network_id: item.network_id, network_name: item.short_name },
		label: item.short_name,
	}));

	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-ai-center p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold '>
						{label}
					</label>
					<div className='p-d-flex p-ai-center '>
						<Dropdown
							{...input}
							id={id}
							placeholder={placeholder}
							options={networks}
							optionLabel='label'
							required
							className='small-dropdown'
						/>
					</div>
				</div>
			)}
		/>
	);
}
