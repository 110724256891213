import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';
import PasswordField from '../../../PasswordField';
import RequisitesPassword from '../../../../pages/Login/components/RequisitesPassword';
import DialogFooter from '../../../DialogFooter';
import {
	addClass,
	removeClass,
} from '../../../../pages/DatosCliente/helpers/Dialog';
import { dialogClient, login, header } from '../../../../configs/textos';
import {
	checkLength,
	checkUpperLoweCase,
	checkNumbersAndSpecialChars,
} from '../../../../helpers/CheckPassword';

const ChangePasswordDialog = ({
	open,
	setOpenDialog,
	loading,
	changePasswordSubmit,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);

	const [checkedMinLength, setCheckedMinLength] = useState(false);
	const [checkedLetters, setCheckedLetters] = useState(false);
	const [checkedSpecialChars, setCheckedSpecialChars] = useState(false);

	function validateFormData(data) {
		const errors = {};

		if (!data.oldPassword) {
			errors.oldPassword = t(login.passwordRequired);
		}
		if (!data.newPassword) {
			errors.newPassword = t(login.passwordRequired);
		}
		if (!data.repeatPassword) {
			errors.repeatPassword = t(login.passwordRequired);
		}
		if (
			data.newPassword &&
			data.repeatPassword &&
			data.repeatPassword !== data.newPassword
		) {
			errors.repeatPassword = t(login.passwordDifferent);
		}
		setCheckedMinLength(checkLength(data.newPassword));
		setCheckedLetters(checkUpperLoweCase(data.newPassword));
		setCheckedSpecialChars(checkNumbersAndSpecialChars(data.newPassword));
		if (!checkedMinLength || !checkedLetters || !checkedSpecialChars) {
			errors.noChecked = true;
		}
		return errors;
	}

	function onSubmit(data) {
		changePasswordSubmit(data);
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	return (
		<>
			<Form
				onSubmit={onSubmit}
				initialValues={{ newPassword: '', repeatPassword: '', oldPassword: '' }}
				validate={validateFormData}
				render={({ handleSubmit, hasValidationErrors, values, form }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={t(header.changePassword)}
							visible={displayBasic}
							style={{ width: '420px' }}
							contentStyle={{
								paddingTop: '30px',
							}}
							onHide={() => {
								restoreDialogAsDefault();
								form.reset();
							}}
							onShow={() => form.reset({})}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									label={t(dialogClient.save)}
									onClickHandler={() => {
										handleSubmit();
									}}
									loading={loading}
								/>
							}
						>
							<div className='card'>
								<div>
									<div className='font16 p-margin'>
										<div className='p-flex-column'>
											<div className='change-password-text'>
												{t(login.changePasswordIntro)}
											</div>
											<PasswordField
												id='oldPassword'
												label={t(login.actualPassword)}
												placeholder={t(login.actualPassword)}
												loading={loading}
												autoComplete='new-password'
											/>
											<div className='change-password-text'>
												{t(login.changeNewPasswordIntro)}
											</div>
											<PasswordField
												id='newPassword'
												label={t(login.newPassword)}
												placeholder={t(login.newPassword)}
												loading={loading}
												autoComplete='new-password'
											/>
											<PasswordField
												id='repeatPassword'
												label={t(login.repeatPassword)}
												placeholder={t(login.confirmPassword)}
												loading={loading}
												autoComplete='new-password'
											/>
											<RequisitesPassword
												password={values?.newPassword}
												checkLetters={checkedLetters}
												checkMinLength={checkedMinLength}
												checkSpecialChars={checkedSpecialChars}
											/>
										</div>
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

export default withTranslation('translations')(ChangePasswordDialog);
