import React, { useState, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import { clientAuthCognito as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { busesPage, dialog } from '../../configs/textos';
import ActionBodyTemplate from '../../components/ActionBodyTemplate';
import BusDialog from './dialogs/BusDialog';
import { editBus, deleteBus } from '../../graphql/mutations';
import { getBuses } from '../../graphql/queries';
import { EDIT_BUS } from '../../configs/operations';
import hasPermission from '../../helpers/HasPermission';

const BusesTable = (props) => {
	const { t } = useTranslation();
	const [openDialogBus, setOpenDialogBus] = useState(false);
	const [openDeleteBus, setOpenDeleteBus] = useState(false);
	const [bus, setBus] = useState(null);
	const toast = useRef(null);
	const { first } = props;

	const {
		global: {
			networkId,
			user: { operations },
		},
	} = props;

	const [editBusMutation, { loading: loadingEditBus }] = useMutation(editBus, {
		client,
		onCompleted() {
			showSuccessToast(t(dialog.editBusSuccess));
			setOpenDialogBus(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
			setOpenDialogBus(false);
		},
	});

	const [deleteBusMutation, { loading: loadingDeleteBus }] = useMutation(
		deleteBus,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.deleteBusSuccess));
				setOpenDeleteBus(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDeleteBus(false);
			},
		}
	);

	function onSubmitBus(input) {
		editBusMutation({
			variables: { input },
			refetchQueries: [
				{
					query: getBuses,
					variables: {
						input: {
							pagination: { limit: 10, offset: first },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'plate', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	function onSubmitDeleteBus() {
		deleteBusMutation({
			variables: { input: { bus_id: bus.bus_id } },
			refetchQueries: [
				{
					query: getBuses,
					variables: {
						input: {
							pagination: { limit: 10, offset: first },
							filter: { network_id: { eq: networkId } },
							orderBy: { field: 'plate', direction: 'ASC' },
						},
					},
				},
			],
		});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	return (
		<>
			<Toast ref={toast} />
			<ResponsiveDataTable {...props}>
				<Column field='plate' header={t(busesPage.plate)} />
				<Column field='vehicle_id' header={t(busesPage.code)} />
				<Column field='service_class_name' header={t(busesPage.serviceType)} />
				<Column
					field='machines'
					header={t(busesPage.machines)}
					body={(rowData) =>
						rowData.machines.length === 0
							? t(busesPage.noMachines)
							: rowData.machines.join(', ')
					}
				/>
				{hasPermission(operations, EDIT_BUS) && (
					<Column
						body={(row) => (
							<ActionBodyTemplate
								onDeleteClick={() => {
									setBus(row);
									setOpenDeleteBus(true);
								}}
								onEditClick={() => {
									setOpenDialogBus(true);
									setBus(row);
								}}
							/>
						)}
					/>
				)}
			</ResponsiveDataTable>
			<BusDialog
				{...props}
				open={openDialogBus}
				setOpenDialog={setOpenDialogBus}
				onSubmitBus={onSubmitBus}
				loading={loadingEditBus}
				networkId={networkId}
				bus={bus}
			/>
			<ConfirmDialog
				visible={openDeleteBus}
				onHide={() => {
					if (!loadingDeleteBus) {
						setOpenDeleteBus(false);
					}
				}}
				message={
					<>
						¿Está seguro de que desea eliminar el autobús{' '}
						<b>{bus?.vehicle_id}</b>?
					</>
				}
				header='Eliminar autobús'
				accept={() => onSubmitDeleteBus(bus)}
				reject={() => setOpenDeleteBus(false)}
				acceptLabel={t(dialog.delete)}
				rejectLabel={t(dialog.cancel)}
				rejectClassName='rejectButton'
				acceptClassName={classNames('acceptDelete', {
					'loading-dialog': loadingDeleteBus,
				})}
				className='confirmDialog'
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(BusesTable));
