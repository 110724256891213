export function checkLength(password) {
	return password?.length >= 10;
}

export function checkUpperLoweCase(password) {
	return /(?=.*[a-z])(?=.*[A-Z])/.test(password);
}

export function checkNumbersAndSpecialChars(password) {
	return /(?=.*[_\W])(?=.*\d)/.test(password);
}
