import React from 'react';
import translate from '../../helpers/Translate';

export default function StateField({ state, i18n, label }) {
	let textColor = '';
	let stateIcon = '';
	if (state) {
		const stateParsed = JSON.parse(state)[translate(i18n)];
		switch (stateParsed) {
			case 'Pendiente':
				stateIcon = 'ri-time-line';
				textColor = '#D91717';
				break;
			case 'Denegado':
				stateIcon = 'ri-close-line';
				textColor = '#D91717';
				break;
			case 'Caducado':
				stateIcon = 'ri-alert-line';
				textColor = '#D91717';
				break;
			case 'Próxima caducidad':
				textColor = '#D91717';
				stateIcon = 'ri-calendar-2-line';
				break;
			case 'Validado':
				textColor = '#0D1E2B';
				stateIcon = 'ri-check-line';
				break;
			default:
				textColor = '#D91717';
		}
	}
	return (
		<div className='p-mb-3'>
			<label htmlFor={label} className=' p-text-bold custom-label-dialog'>
				{label}
			</label>
			<div
				className='p-d-flex p-ai-center state-input p-ml-1 p-mt-2'
				style={{ color: textColor }}
			>
				<i className={`${stateIcon} pi p-mr-1`} />
				{state ? JSON.parse(state)[translate(i18n)] : null}
			</div>
		</div>
	);
}
