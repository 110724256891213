import React, { useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { header } from '../../../../configs/textos';

function UserDropdown({ logOut, setOpenDialog, userEmail }) {
	const menu = useRef(null);
	const { t } = useTranslation();
	const items = [
		{
			label: userEmail,
			disabled: true,
			className: 'item-username',
		},
		{ label: t(header.changePassword), command: () => setOpenDialog(true) },
		{
			label: t(header.logout),
			command: () => logOut(),
		},
	];

	return (
		<>
			<Menu
				model={items}
				popup
				ref={menu}
				id='popup_menu'
				className='menu-user'
			/>
			<Button
				icon='pi pi-fw pi-user'
				onClick={(event) => menu.current.toggle(event)}
				aria-controls='popup_menu'
				aria-haspopup
				className='p-link layout-menu-button p-p-0 p-ml-2 top-bar-user-icon'
			/>
		</>
	);
}

export default withTranslation('translations')(UserDropdown);
