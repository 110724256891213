import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getWalletDef } from '../../graphql/queries';
import WalletDefCard from './WalletDefCard';
import CertificatesTable from './CertificatesTable';
import { pocketDef } from '../../configs/textos';

const WalletDef = (props) => {
	const {
		global: {
			user: { network_id: networkId },
		},
	} = props;
	const { walletDefId } = useParams();
	const history = useHistory();

	const { data, loading } = useQuery(getWalletDef, {
		client,
		variables: { walletDefId },
	});
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			history.push(pocketDef.url);
		}
	}, [networkId]);

	if (loading) return <LoadingSpinner />;

	const walletDef = data?.getWalletDefDetails || {};
	const certificates = walletDef?.certificates || [];

	return (
		<div className='p-container'>
			<WalletDefCard {...props} walletDef={walletDef} />
			<CertificatesTable {...props} certificates={certificates} />
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(WalletDef);
