import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { getDocuments } from '../../../graphql/queries';
import mapFilter from './mapFilter';

const useGetDocuments = ({
	offset,
	inputQuery,
	networkId,
	refetchQuery,
	setRefetchQuery,
}) => {
	const pagination = { limit: 10, offset };
	const inputFilter = mapFilter(inputQuery, networkId, pagination);

	const queryParams = {
		client,
		variables: {
			inputFilter,
		},
		fetchPolicy: 'network-only',
	};

	const { data, loading, error, refetch } = useQuery(getDocuments, queryParams);
	if (refetchQuery) {
		refetch(queryParams.variables);
		setRefetchQuery(false);
	}

	return { data, loading, error };
};

export default useGetDocuments;
