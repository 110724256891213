import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import { pocketsPage, pocketDef } from '../../configs/textos';
import { dateFormatUTC } from '../../helpers/DateFormat';
import translate from '../../helpers/Translate';

const PocketsTable = (props) => {
	const { t } = useTranslation();
	const { i18n } = props;

	return (
		<ResponsiveDataTable {...props}>
			<Column
				field='name'
				header={t(pocketsPage.name)}
				body={(row) =>
					row?.name ? JSON.parse(row.name)[translate(i18n)] : '-'
				}
			/>
			<Column
				field='description'
				header={t(pocketsPage.description)}
				body={(row) =>
					row?.description ? JSON.parse(row.description)[translate(i18n)] : '-'
				}
			/>
			<Column
				field='init_date'
				header={t(pocketsPage.initDate)}
				body={(row) =>
					row?.init_date ? dateFormatUTC(row.init_date, 'DD/MM/YYYY') : '-'
				}
			/>
			<Column
				field='end_date'
				header={t(pocketsPage.endDate)}
				body={(row) =>
					row?.end_date ? dateFormatUTC(row.end_date, 'DD/MM/YYYY') : '-'
				}
			/>
			<Column
				body={(row) => (
					<NavLink
						className='p-datatable-column-button'
						to={`${pocketDef.url}/${row.pocket_def_id}`}
						exact
					>
						{t(pocketsPage.see)}
					</NavLink>
				)}
			/>
		</ResponsiveDataTable>
	);
};

export default withTranslation('translations')(PocketsTable);
