import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import LoadingSpinner from '../../../components/LoadingSpinner';
import getFileURL from '../../../helpers/S3Service';

export default function Photo({
	photo,
	noEdit,
	hasPermission,
	onClickEditPhoto,
}) {
	const [photoUrl, setPhotoUrl] = useState(null);
	const [loadingPhoto, setLoadingPhoto] = useState(true);

	useEffect(() => {
		async function getUrl() {
			const url = await getFileURL(photo);
			if (url) {
				setPhotoUrl(url);
				setLoadingPhoto(false);
			}
		}
		if (photo) {
			getUrl();
		} else {
			setPhotoUrl(null);
		}
	}, [photo]);

	return (
		<div className='photo-container'>
			{
				/* eslint-disable no-nested-ternary */
				photo && loadingPhoto ? (
					<div className='p-pt-5 p-pl-5'>
						<LoadingSpinner />
					</div>
				) : photo && photoUrl && !loadingPhoto ? (
					<img src={photoUrl} className='userphoto' alt='Foto de usuario' />
				) : (
					<i className='pi pi-user userphoto-placeholder' />
				)
			}

			{!noEdit && hasPermission && (
				<Button
					icon='pi pi-pencil'
					className='edit-icon'
					onClick={() => onClickEditPhoto()}
				/>
			)}
		</div>
	);
}
