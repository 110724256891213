import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import * as projectAction from '../../store/actions/projectAction';
import { documentsPage } from '../../configs/textos';
import useGetDocuments from './hooks/useGetDocuments';
import DocumentsTable from './DocumentsTable';
import {
	documentsNetworkError,
	documentsNetworkTableError,
} from '../../configs/mensajes';

function Documents(props) {
	const { t } = useTranslation();
	const toast = useRef(null);

	const {
		global: { networkId },
	} = props;

	const [offset, setOffset] = useState(0);
	const [totalDocuments, setTotalDocuments] = useState(0);
	const [inputQuery, setInputQuery] = useState({});

	const [refetchQuery, setRefetchQuery] = useState(false);

	const { data, loading, error } = useGetDocuments({
		offset,
		inputQuery,
		networkId,
		refetchQuery,
		setRefetchQuery,
	});

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	if (error) {
		showErrorToast(documentsNetworkError);
	}

	const { items: documents = [], total } = data?.listCertificates || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalDocuments(total);
		}
	}, [total]);

	const onPage = ({ first }) => !loading && setOffset(first);

	function onFilter(input) {
		if (JSON.stringify(input) === JSON.stringify(inputQuery) && !refetchQuery) {
			setRefetchQuery(true);
		}
		setOffset(0);
		setInputQuery(input);
	}

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between'>
				<div className='p-page-title p-as-end'>{t(documentsPage.title)}</div>
				<div className='p-d-flex '>
					<div className='color-div-pending' />
					<div className='count-pending p-d-flex p-ai-center'>
						{loading ? (
							<div className='p-d-flex p-text-center'>
								<i className='pi pi-spin pi-spinner loadingCount' />
							</div>
						) : (
							documents[0]?.total_pending || 0
						)}
						<div className='p-ml-2'>
							{documents[0]?.total_pending === 1
								? t(documentsPage.pendingSingular)
								: t(documentsPage.pending)}
						</div>
					</div>
				</div>
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<DocumentsTable
						value={documents}
						loading={data?.listCertificates == null && loading}
						emptyMessage={t(documentsNetworkTableError)}
						rows={10}
						total={totalDocuments}
						onPage={onPage}
						first={offset}
						lazy
						onFilter={onFilter}
					/>
				</Card>
			</div>
		</div>
	);
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Documents));
