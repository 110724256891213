import React, { useEffect } from 'react';
import moment from 'moment';
import { Field } from 'react-final-form';
import { Calendar } from 'primereact/calendar';
import classNames from 'classnames';
import { locale, addLocale } from 'primereact/api';
import { dialogCertificados } from '../configs/textos';

function DateField({
	t,
	certificatePhoto,
	onChangeEnd,
	onChangeInit,
	dateEnd,
	dateInit,
	setErrorDates,
	errorDates,
	disabled,
	verticalStyle,
}) {
	useEffect(() => {
		if (dateEnd && dateInit) {
			if (moment(dateEnd).isBefore(dateInit)) {
				setErrorDates(
					'La fecha de fin debe ser posterior a la fecha de inicio'
				);
			} else {
				setErrorDates('');
			}
		}
	}, [dateInit, dateEnd]);

	useEffect(() => {
		if (certificatePhoto) {
			onChangeEnd(new Date(certificatePhoto.exp_date));
			onChangeInit(new Date(certificatePhoto.init_date));
		}
	}, [certificatePhoto]);

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre',
		],
		today: 'Hoy',
		clear: 'Limpiar',
	});

	locale('es');

	return (
		<>
			<Field
				name='certificado'
				render={({ input }) => (
					<div
						className={classNames('p-mb-3', {
							'date-margin': verticalStyle,
							'p-d-flex p-ai-center p-jc-between': !verticalStyle,
						})}
					>
						<label htmlFor='certificado' className='p-text-bold'>
							{t(dialogCertificados.initDate)}
						</label>
						<div className='p-mt-2'>
							<Calendar
								{...input}
								value={dateInit}
								inputStyle={{ width: '222px' }}
								onChange={(e) => onChangeInit(e.value)}
								placeholder='dd/mm/aaaa'
								dateFormat='dd/mm/yy'
								disabled={disabled}
							/>
							<button
								type='button'
								className='date-button p-ml-3'
								onClick={() => onChangeInit(new Date())}
								disabled={disabled}
							>
								{t('Hoy')}
							</button>
						</div>
					</div>
				)}
			/>
			<Field
				name='certificado'
				render={({ input }) => (
					<div
						className={classNames('p-mb-3', {
							'date-margin': verticalStyle,
							'p-d-flex p-ai-center p-jc-between': !verticalStyle,
						})}
					>
						<label htmlFor='certificado' className='p-text-bold'>
							{t(dialogCertificados.expired)}
						</label>
						<div className='p-mt-2'>
							<Calendar
								{...input}
								placeholder='dd/mm/aaaa'
								value={dateEnd}
								inputStyle={{ width: '222px' }}
								dateFormat='dd/mm/yy'
								onChange={(e) => onChangeEnd(e.value)}
								disabled={disabled}
							/>
							<button
								type='button'
								className='date-button p-ml-3'
								onClick={() => onChangeEnd(new Date('01/01/9999'))}
								disabled={disabled}
							>
								{t(dialogCertificados.notExpired)}
							</button>
							<p className='p-mt-1'>
								{errorDates && <small className='p-error'>{errorDates}</small>}
							</p>
						</div>
					</div>
				)}
			/>
		</>
	);
}

export default DateField;
